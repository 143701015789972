"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllPromos = exports.deleteFavoriteCategory = exports.addFavoriteCategory = exports.changeCategory = exports.getAllBounties = exports.getAllCategories = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
exports.getAllCategories = (0, redux_saga_routines_1.createRoutine)('articles/GET_ALL_CATEGORIES');
exports.getAllBounties = (0, redux_saga_routines_1.createRoutine)('articles/GET_ALL_BOUNTIES');
exports.changeCategory = (0, redux_saga_routines_1.createRoutine)('articles/CHANGE_CATEGORY');
exports.addFavoriteCategory = (0, redux_saga_routines_1.createRoutine)('articles/ADD_FAVORITE_CATEGORY');
exports.deleteFavoriteCategory = (0, redux_saga_routines_1.createRoutine)('articles/DELETE_FAVORITE_CATEGORY');
exports.getAllPromos = (0, redux_saga_routines_1.createRoutine)('articles/GET_ALL_PROMOS');
