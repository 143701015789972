"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserHelperDataWatch = exports.expandPostWatch = exports.deleteReactionWatch = exports.commentWatch = exports.likeWatch = exports.createSocialPostWatch = exports.getSocialFeedWatch = exports.socialFeedWatchers = void 0;
var effects_1 = require("redux-saga/effects");
var actions = __importStar(require("./SocialFeedActions"));
var api = __importStar(require("./SocialFeedApi"));
exports.socialFeedWatchers = [
    getSocialFeedWatch,
    createSocialPostWatch,
    likeWatch,
    commentWatch,
    deleteReactionWatch,
    expandPostWatch,
    getUserHelperDataWatch
];
function getSocialFeedWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getSocialFeed.TRIGGER, getSocialFeed)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getSocialFeedWatch = getSocialFeedWatch;
function createSocialPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.createSocialPost.TRIGGER, createSocialPost)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.createSocialPostWatch = createSocialPostWatch;
function likeWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.like.TRIGGER, like)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.likeWatch = likeWatch;
function commentWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.createSocialComment.TRIGGER, comment)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.commentWatch = commentWatch;
function deleteReactionWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.deleteReaction.TRIGGER, deleteReaction)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.deleteReactionWatch = deleteReactionWatch;
function expandPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.expandPost.TRIGGER, expandPost)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.expandPostWatch = expandPostWatch;
function getUserHelperDataWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getUserHelperData.TRIGGER, getUserHelperData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getUserHelperDataWatch = getUserHelperDataWatch;
function getSocialFeed(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getSocialFeed(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.results) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getSocialFeed.success({
                        posts: serverResponse.results,
                        more: payload.more
                    }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                console.log('cannot load posts ', serverResponse);
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function createSocialPost(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.createSocialPost(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.createSocialPost.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.createSocialPost.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function like(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.like(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.like.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.like.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function comment(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.comment(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.createSocialComment.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.createSocialComment.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function deleteReaction(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.deleteReaction(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.deleteReaction.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.deleteReaction.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function expandPost(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getComments(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.results) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.expandPost.success(serverResponse.results))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.expandPost.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getUserHelperData(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getUserHelper(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getUserHelperData.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.getUserHelperData.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
