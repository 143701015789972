"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var logger = function (store) { return function (next) { return function (action) {
    var result = next(action);
    if ((process.env.NODE_ENV === 'development' || window.location.hostname == "beta.loop.markets") && action.type != "SETSCROLL/TRIGGER") {
        console.group(action.type);
        console.info('dispatching', action);
        console.log('next state', store.getState());
        console.groupEnd();
        return result;
    }
    return result;
}; }; };
exports.default = logger;
