"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
// src/LanguageSelector.tsx
var react_1 = require("react");
var languages = [
    { value: "en", label: "English", flag: (0, jsx_runtime_1.jsx)("img", { src: "/assets/icons/en-us.svg", alt: "US Flag" }) },
    { value: "es", label: "Español", flag: (0, jsx_runtime_1.jsx)("img", { src: "/assets/icons/es-us.svg", alt: "Spain Flag" }) },
];
function LanguageSelector() {
    var _a, _b;
    var pattern = /(es)/;
    var _c = (0, react_1.useState)(localStorage.getItem("i18nextLng") ||
        (((_a = languages.find(function (lang) { return lang.value === navigator.language; })) === null || _a === void 0 ? void 0 : _a.value) || "en-US")), selectedLanguage = _c[0], setSelectedLanguage = _c[1];
    var _d = (0, react_1.useState)(false), showFullText = _d[0], setShowFullText = _d[1];
    (0, react_1.useEffect)(function () {
        localStorage.setItem("i18nextLng", selectedLanguage);
    }, [selectedLanguage]);
    var handleToggleText = function () {
        setShowFullText(function (prevShowFullText) { return !prevShowFullText; });
    };
    var handleSelectLanguage = function (language) {
        setSelectedLanguage(language);
        setShowFullText(false);
        window.location.reload();
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "language-selector" }, { children: [(0, jsx_runtime_1.jsxs)("button", __assign({ onClick: handleToggleText }, { children: [(_b = languages.find(function (lang) { return selectedLanguage.includes(lang.value); })) === null || _b === void 0 ? void 0 : _b.flag, " ", pattern.test(selectedLanguage) ? "ES" : "EN"] })), showFullText && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "dropdown-options" }, { children: languages.map(function (lang) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ onClick: function () { return handleSelectLanguage(lang.value); } }, { children: [lang.flag, " ", lang.label] }), lang.value)); }) })))] })));
}
exports.default = LanguageSelector;
