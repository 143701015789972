"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetCommentSuccess = exports.dismissReportRoutine = exports.reportArticleRoutine = exports.sendPrerenderRoutine = exports.getPrerenderRoutine = exports.setCuratePost = exports.upVoteToPostComment = exports.reportToPostComment = exports.updateToPostComment = exports.replyToPostComment = exports.getPostRating = exports.ratePost = exports.sendPostRating = exports.sendPostComment = exports.getPostComments = exports.clearPostInfo = exports.setPostInfo = exports.getAuthorPost = exports.getPostInfo = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
// creating routine
exports.getPostInfo = (0, redux_saga_routines_1.createRoutine)('post/GET_POST_INFO');
exports.getAuthorPost = (0, redux_saga_routines_1.createRoutine)('post/GET_AUTHOR_POST');
exports.setPostInfo = (0, redux_saga_routines_1.createRoutine)('post/SET_POST_INFO');
exports.clearPostInfo = (0, redux_saga_routines_1.createRoutine)('post/CLEAR_POST_INFO');
exports.getPostComments = (0, redux_saga_routines_1.createRoutine)('post/GET_POST_COMMENTS');
exports.sendPostComment = (0, redux_saga_routines_1.createRoutine)('post/SEND_POST_COMMENT');
exports.sendPostRating = (0, redux_saga_routines_1.createRoutine)('post/SEND_POST_RATING');
exports.ratePost = (0, redux_saga_routines_1.createRoutine)('post/RATE_POST');
exports.getPostRating = (0, redux_saga_routines_1.createRoutine)('post/GET_POST_RATING');
exports.replyToPostComment = (0, redux_saga_routines_1.createRoutine)('post/SEND_COMMENT_REPLY');
exports.updateToPostComment = (0, redux_saga_routines_1.createRoutine)('post/SEND_COMMENT_UPDATE');
exports.reportToPostComment = (0, redux_saga_routines_1.createRoutine)('post/SEND_COMMENT_REPORT');
exports.upVoteToPostComment = (0, redux_saga_routines_1.createRoutine)('post/SEND_COMMENT_UPVOTE');
exports.setCuratePost = (0, redux_saga_routines_1.createRoutine)('post/SEND_CURATE_POST');
exports.getPrerenderRoutine = (0, redux_saga_routines_1.createRoutine)('post/GET_PRERENDER');
exports.sendPrerenderRoutine = (0, redux_saga_routines_1.createRoutine)('post/SEND_PRERENDER');
exports.reportArticleRoutine = (0, redux_saga_routines_1.createRoutine)('post/REPORT_ARTICLE');
exports.dismissReportRoutine = (0, redux_saga_routines_1.createRoutine)('post/DISMISS_REPORT_ARTICLE');
exports.resetCommentSuccess = (0, redux_saga_routines_1.createRoutine)('post/RESET_COMMENT_SUCCESS');
