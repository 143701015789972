"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.previewArticleWatch = exports.deleteArticleWatch = exports.updateArticleWatch = exports.uploadFeaturedImageWatch = exports.postArticleWatch = exports.editorWatchers = void 0;
var effects_1 = require("redux-saga/effects");
var editorActions = __importStar(require("./EditorActions"));
var api = __importStar(require("../../post/postApi"));
var EditorApi_1 = require("./EditorApi");
exports.editorWatchers = [
    postArticleWatch,
    uploadFeaturedImageWatch,
    updateArticleWatch,
    getMentionSuggestionsWatcher,
    deleteArticleWatch,
    previewArticleWatch,
    getPreviewArticleWatch,
];
function postArticleWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.createArticleRoutine.TRIGGER, newArticle)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.postArticleWatch = postArticleWatch;
function uploadFeaturedImageWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.uploadFeaturedImageRoutine.TRIGGER, uploadImage)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.uploadFeaturedImageWatch = uploadFeaturedImageWatch;
function updateArticleWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.updateArticleRoutine.TRIGGER, updateArticle)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.updateArticleWatch = updateArticleWatch;
function deleteArticleWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.deleteArticleRoutine.TRIGGER, deleterticle)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.deleteArticleWatch = deleteArticleWatch;
function uploadImage(data) {
    var slug, imageData, serverResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.log('fired');
                slug = data.slug, imageData = __rest(data, ["slug"]);
                return [4 /*yield*/, (0, EditorApi_1.uploadFeaturedImage)(imageData)];
            case 1:
                serverResponse = _a.sent();
                if (!serverResponse.data) return [3 /*break*/, 5];
                return [4 /*yield*/, (0, effects_1.put)(editorActions.uploadFeaturedImageRoutine.success(serverResponse.data))];
            case 2:
                _a.sent();
                if (!slug) return [3 /*break*/, 4];
                return [4 /*yield*/, api.sendPrerender("https://www.loop.markets/".concat(slug))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, (0, effects_1.put)(editorActions.uploadFeaturedImageRoutine.failure(serverResponse))];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}
function newArticle(data) {
    var serverResponse, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 8, , 10]);
                return [4 /*yield*/, (0, EditorApi_1.createArticle)(data)];
            case 1:
                serverResponse = _a.sent();
                if (!(serverResponse.status === 201)) return [3 /*break*/, 5];
                if (!(serverResponse.data.status == "publish" && serverResponse.data.slug)) return [3 /*break*/, 3];
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'publish-article',
                    'event_category': 'community',
                    'event_action': 'publish-article',
                    'event_label': serverResponse.data.title.rendered //the title of the article
                });
                return [4 /*yield*/, api.sendPrerender("https://www.loop.markets/".concat(serverResponse.data.slug))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                if (serverResponse.data.status == "draft") {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'save-article',
                        'event_category': 'community',
                        'event_action': 'save-article',
                        'event_label': serverResponse.data.title.rendered //the title of the article
                    });
                }
                return [4 /*yield*/, (0, effects_1.put)(editorActions.createArticleRoutine.success(serverResponse.data))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, (0, effects_1.put)(editorActions.createArticleRoutine.failure(serverResponse))];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_1 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)(editorActions.createArticleRoutine.failure(error_1))];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function updateArticle(data) {
    var serverResponse, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, (0, EditorApi_1.updateArticleCall)(data)];
            case 1:
                serverResponse = _a.sent();
                if (!((serverResponse === null || serverResponse === void 0 ? void 0 : serverResponse.status) === 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(editorActions.updateArticleRoutine.success(serverResponse.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(editorActions.updateArticleRoutine.failure(serverResponse))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_2 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)(editorActions.updateArticleRoutine.failure(error_2))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function deleterticle(data) {
    var serverResponse, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, (0, EditorApi_1.deleteArticle)(data)];
            case 1:
                serverResponse = _a.sent();
                if (!(serverResponse.status === 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(editorActions.deleteArticleRoutine.success(serverResponse.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(editorActions.deleteArticleRoutine.failure(serverResponse))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_3 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)(editorActions.deleteArticleRoutine.failure(error_3))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getMentionSuggestionsWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.getMentionSuggestions.TRIGGER, getMentionSuggestionsWorker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function getMentionSuggestionsWorker(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, EditorApi_1.getMentionSuggestions)(payload.userId)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status === 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(editorActions.getMentionSuggestions.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(editorActions.getMentionSuggestions.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function previewArticleWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.previewArticleRoutine.TRIGGER, prevArticle)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.previewArticleWatch = previewArticleWatch;
function prevArticle(data) {
    var error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4 /*yield*/, (0, effects_1.put)(editorActions.previewArticleRoutine.success(data))];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2:
                error_4 = _a.sent();
                return [4 /*yield*/, (0, effects_1.put)(editorActions.previewArticleRoutine.failure(error_4))];
            case 3:
                _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function getPreviewArticleWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(editorActions.getPreviewArticleRoutine.TRIGGER, getPreviewArticleWorker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function getPreviewArticleWorker(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, effects_1.put)(editorActions.getPreviewArticleRoutine.success(payload))];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
