"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions = __importStar(require("./FriendsActions"));
var defaultState = {
    userStatus: undefined,
    loadingList: false,
    friends: [],
    requests: [],
    requestFriendshipStatus: undefined,
    friendshipsStatus: {},
    friendsCount: null,
    requestsCount: null
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case actions.getRequests.TRIGGER:
        case actions.getFriends.TRIGGER: {
            return __assign(__assign({}, state), { loadingList: true });
        }
        case actions.getFriends.SUCCESS: {
            return __assign(__assign({}, state), { loadingList: false, friends: payload.friend_lists, friendsCount: payload.total_friends_count });
        }
        case actions.getRequests.SUCCESS: {
            return __assign(__assign({}, state), { loadingList: false, requests: payload.friendships_requests_list, requestsCount: payload.total_friendships_requests_count });
        }
        case actions.getFriends.FAILURE:
        case actions.getRequests.FAILURE: {
            return __assign(__assign({}, state), { loadingList: false });
        }
        case actions.requestFriendship.TRIGGER: {
            var targetId = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId] = 'loading';
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus });
        }
        case actions.requestFriendship.SUCCESS: {
            var targetId = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId] = 'Cancel Request';
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus });
        }
        case actions.requestFriendship.FAILURE: {
            return __assign(__assign({}, state), { requestFriendshipStatus: undefined });
        }
        case actions.rejectFriendshipRequest.TRIGGER: {
            var targetId_1 = payload.targetId;
            var newRequests = __spreadArray([], state.requests, true);
            var userIndex = newRequests.findIndex(function (_a) {
                var id = _a.id;
                return id == targetId_1;
            });
            newRequests[userIndex].friendButtonLoading = true;
            return __assign(__assign({}, state), { requests: newRequests });
        }
        case actions.rejectFriendshipRequest.SUCCESS: {
            var targetId_2 = payload.targetId;
            var newRequests = __spreadArray([], state.requests, true);
            var userIndex = newRequests.findIndex(function (_a) {
                var id = _a.id;
                return id == targetId_2;
            });
            newRequests.splice(userIndex, 1);
            return __assign(__assign({}, state), { requests: newRequests, userStatus: undefined });
        }
        case actions.rejectFriendshipRequest.FAILURE: {
            return __assign(__assign({}, state), { userStatus: undefined });
        }
        case actions.cancelFriendshipRequest.TRIGGER: {
            var targetId = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId] = 'loading';
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus });
        }
        case actions.cancelFriendshipRequest.SUCCESS: {
            var targetId = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId] = 'Add Friend';
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus });
        }
        case actions.cancelFriendshipRequest.FAILURE: {
            return __assign(__assign({}, state), { userStatus: undefined });
        }
        case actions.acceptFriendshipRequest.TRIGGER: {
            var targetId_3 = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            console.log('new friendship status:', newFriendshipsStatus);
            newFriendshipsStatus[targetId_3] = 'loading';
            var newRequests = __spreadArray([], state.requests, true);
            if (newRequests.length !== 0) {
                var userIndex = newRequests.findIndex(function (_a) {
                    var id = _a.id;
                    return id == targetId_3;
                });
                newRequests[userIndex].acceptFriendshipButtonLoading = true;
            }
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus, requests: newRequests });
        }
        case actions.acceptFriendshipRequest.SUCCESS: {
            var targetId_4 = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId_4] = 'Unfriendddd';
            var newRequests = __spreadArray([], state.requests, true);
            if (newRequests.length !== 0) {
                var userIndex = newRequests.findIndex(function (_a) {
                    var id = _a.id;
                    return id == targetId_4;
                });
                newRequests.splice(userIndex, 1);
            }
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus, requests: newRequests });
        }
        case actions.acceptFriendshipRequest.FAILURE: {
            return __assign(__assign({}, state), { userStatus: undefined });
        }
        case actions.removeFriend.TRIGGER: {
            var targetId_5 = payload.targetId;
            var newFriends = __spreadArray([], state.friends, true);
            var userIndex = newFriends.findIndex(function (_a) {
                var id = _a.id;
                return id == targetId_5;
            });
            if (userIndex >= 0) {
                newFriends[userIndex].friendButtonLoading = true;
            }
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId_5] = 'loading';
            return __assign(__assign({}, state), { friends: newFriends, friendshipsStatus: newFriendshipsStatus });
        }
        case actions.removeFriend.SUCCESS: {
            var targetId_6 = payload.targetId;
            var newFriends = __spreadArray([], state.friends, true);
            var userIndex = newFriends.findIndex(function (_a) {
                var id = _a.id;
                return id == targetId_6;
            });
            newFriends.splice(userIndex, 1);
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId_6] = 'Add Friend';
            return __assign(__assign({}, state), { friends: newFriends, friendshipsStatus: newFriendshipsStatus });
        }
        case actions.removeFriend.FAILURE: {
            return __assign(__assign({}, state), { userStatus: undefined });
        }
        case actions.checkFriendshipStatus.TRIGGER: {
            var targetId = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId] = 'loading';
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus });
        }
        case actions.checkFriendshipStatus.SUCCESS: {
            var targetId = payload.targetId;
            var newFriendshipsStatus = __assign({}, state.friendshipsStatus);
            newFriendshipsStatus[targetId] = payload['friendship status'];
            return __assign(__assign({}, state), { friendshipsStatus: newFriendshipsStatus });
        }
        default:
            return state;
    }
}
exports.default = reducer;
