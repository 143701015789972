"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CollectiblePagesTranslation = [
    {
        en: {
            translations: {
                collectibleFitness: {
                    title: "<h3> Ticket Sales <br /> through NFTs for Events, <br /> Artists and Brands</h3>",
                    contentTitle: "NFT Tickets offer Functionality and <br /> <span>Collectible Memorabilia</span> <br /> <span>for Fans</span>",
                    list: [
                        {
                            name: "<p>NFT Tickets are scannable <b>from any mobile device</b></p>",
                            bg: "/assets/images/collectibles/tickets01.png",
                        },
                        {
                            name: "<p>Reward your Fans <b>with NFT Tickets to live exclusive experiences</b></p>",
                            bg: "/assets/images/collectibles/tickets02.png",
                        },
                        {
                            name: "<p>Share discounts <b>and special merchandising offers</b></p>",
                            bg: "/assets/images/collectibles/tickets03.png",
                        },
                    ],
                },
                collectiblePrivate: {
                    title: "<h3>Private <br /> Memberships for <br /> your SuperFans</h3>",
                    contentTitle: "Monetize your content by providing <br /> <span>exclusive access</span> <br /> <span>through memberships</span>",
                    list: [
                        {
                            name: "<p>Create your own <b>Private Community to interact with your Fans</b></p>",
                            bg: "/assets/images/collectibles/private01.png",
                        },
                        {
                            name: "<p><b>Share Exclusive Content</b></p>",
                            bg: "/assets/images/collectibles/private02.png",
                        },
                        {
                            name: "<p><b>Generate recurring income</b> with Memberships</p>",
                            bg: "/assets/images/collectibles/private03.png",
                        },
                    ],
                },
                collectibleDigital: {
                    title: "<h3>NFT of Digital Media: <br /> Collectibles from <br /> Art - to - Z</h3>",
                    contentTitle: "Sell your <span> Digital Art, Courses, <br /> E-Books, Certificates </span> or any <br /> digital thing",
                    list: [
                        {
                            name: "<p> From <b>Digital Art</b> to <br /> <b>Coupons</b>, digitize anything as a <b>collectible</b> </p>",
                            bg: "/assets/images/collectibles/digital01.png",
                        },
                        {
                            name: "<p> <b>Monetize your media</b> and share directly with fans and followers</p>",
                            bg: "/assets/images/collectibles/digital02.png",
                        },
                        {
                            name: "<p> Get <b>proof of ownership</b> and <b>greater file transfer power</b> </p>",
                            bg: "/assets/images/collectibles/digital03.png",
                        },
                    ],
                },
                collectibleMusic: {
                    title: "<h3> Music NFTs allow <br /> artists to thrive using <br /> the next generation of the web </h3>",
                    contentTitle: "<span> Digital Collectibles </span> are the <br /> bridge to success in the new <br /> era of media",
                    list: [
                        {
                            name: "<p><b>Share</b> your Music directly with Fans</p>",
                            bg: "/assets/images/collectibles/MusicPage1.png",
                        },
                        {
                            name: "<p><b>Control the creative direction</b> of your art</p>",
                            bg: "/assets/images/collectibles/MusicPage2.png",
                        },
                        {
                            name: "<p><b>Generate income sources</b> where you benefit first</p>",
                            bg: "/assets/images/collectibles/MusicPage3.png",
                        },
                    ],
                }
            },
        },
        es: {
            translations: {
                collectibleFitness: {
                    title: "<h3> Venta de Entradas <br /> a través de NFTs para Eventos, <br /> Artistas y Marcas</h3>",
                    contentTitle: "Las Entradas con NFTs ofrecen Funcionalidad y <br /> <span>Memorabilia Coleccionable</span> <br /> <span>para los Fans</span>",
                    list: [
                        {
                            name: "<p>Las Entradas con NFTs son escaneables <b>desde cualquier dispositivo móvil</b></p>",
                            bg: "/assets/images/collectibles/tickets01.png",
                        },
                        {
                            name: "<p>Premia a tus Fans <b>con Entradas NFTs para que vivan experiencias exclusivas</b></p>",
                            bg: "/assets/images/collectibles/tickets02.png",
                        },
                        {
                            name: "<p>Comparte descuentos <b>y ofertas especiales de merchandising</b></p>",
                            bg: "/assets/images/collectibles/tickets03.png",
                        },
                    ],
                },
                collectiblePrivate: {
                    title: "<h3>Membresias <br /> Privadas para <br /> tu SuperFans</h3>",
                    contentTitle: "Monetiza tu contenido brindando <br /> <span>acceso exclusivo</span> <br /> <span>a través de membresias</span>",
                    list: [
                        {
                            name: "<p>Crea tu propia <b>Comunidad Privada para interactuar con tus Fans</b>></p>",
                            bg: "/assets/images/collectibles/private01.png",
                        },
                        {
                            name: "<p><b>Comparte Contenido Exclusivo</b></p>",
                            bg: "/assets/images/collectibles/private02.png",
                        },
                        {
                            name: "<p><b>Genera ingreso recurrente</b> con Membresias</p>",
                            bg: "/assets/images/collectibles/private03.png",
                        },
                    ],
                },
                collectibleDigital: {
                    title: "<h3>NFT de Medios Digitales: <br /> Coleccionables desde <br /> Arte - a la Z</h3>",
                    contentTitle: "Vende tu <span> Arte Digital, Cursos, <br /> E-Books, Certificados </span> o cualquier <br /> cosa digital",
                    list: [
                        {
                            name: "<p> Desde <b>Arte Digital</b> hasta <br /> <b>Cupones</b>, digitalice cualquier cosa como <b>coleccionable</b> </p>",
                            bg: "/assets/images/collectibles/digital01.png",
                        },
                        {
                            name: "<p> <b>Monetiza tus medios</b> y comparte directamente con fans y seguidores</p>",
                            bg: "/assets/images/collectibles/digital02.png",
                        },
                        {
                            name: "<p> Obtenga <b>prueba de propiedad</b> y <b>mayor poder de transferencia</b> de archivos </p>",
                            bg: "/assets/images/collectibles/digital03.png",
                        },
                    ],
                },
                collectibleMusic: {
                    title: "<h3> Music NFTs permiten <br /> a los artistas prosperar utilizando <br /> la próxima generación de la web </h3>",
                    contentTitle: "<span> Coleccionables Digitales </span> son la <br /> puente de éxito en la nueva <br /> era de medios",
                    list: [
                        {
                            name: "<p><b>Comparte</b> tu Música directamente con Fans</p>",
                            bg: "/assets/images/collectibles/MusicPage1.png",
                        },
                        {
                            name: "<p><b>Controla la dirección creativa</b> de tu arte</p>",
                            bg: "/assets/images/collectibles/MusicPage2.png",
                        },
                        {
                            name: "<p><b>Genera fuentes de ingreso</b> donde tu se beneficia primero</p>",
                            bg: "/assets/images/collectibles/MusicPage3.png",
                        },
                    ],
                },
            },
        },
    },
];
exports.default = CollectiblePagesTranslation;
