"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions = __importStar(require("./postActions"));
var defaultState = {
    postLoading: false,
    moreArticlesLoading: false,
    postComments: [],
    commentPages: 0,
    commentsLoading: false,
    ratingLoading: true,
    ratingSending: false,
    postRating: {},
    postInfo: {},
    moreArticles: {},
    preRendered: false,
    preRenderChecked: false,
    reportMessage: false,
    curateLoading: null,
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case actions.dismissReportRoutine.TRIGGER: {
            return __assign(__assign({}, state), { reportMessage: false });
        }
        case actions.reportArticleRoutine.TRIGGER: {
            return __assign(__assign({}, state), { reportMessage: 'Sending Report' });
        }
        case actions.reportArticleRoutine.SUCCESS: {
            return __assign(__assign({}, state), { reportMessage: payload.message });
        }
        case actions.reportArticleRoutine.FAILURE: {
            console.log(payload.data.message);
            return __assign(__assign({}, state), { reportMessage: payload.data.message });
        }
        case actions.getPrerenderRoutine.TRIGGER: {
            return __assign(__assign({}, state), { preRendered: false, preRenderChecked: false });
        }
        case actions.getPrerenderRoutine.SUCCESS: {
            return __assign(__assign({}, state), { preRendered: true, preRenderChecked: true });
        }
        case actions.getPrerenderRoutine.FAILURE: {
            return __assign(__assign({}, state), { preRendered: false, preRenderChecked: true });
        }
        case actions.getPostInfo.TRIGGER: {
            return __assign(__assign({}, state), { postLoading: true, reportMessage: false });
        }
        case actions.getPostInfo.SUCCESS: {
            return __assign(__assign({}, state), { postInfo: payload, postLoading: false });
        }
        case actions.getPostInfo.FAILURE: {
            return __assign(__assign({}, state), { postInfo: false, postLoading: false });
        }
        case actions.getAuthorPost.TRIGGER: {
            return __assign(__assign({}, state), { moreArticlesLoading: true });
        }
        case actions.getAuthorPost.SUCCESS: {
            return __assign(__assign({}, state), { moreArticles: payload, moreArticlesLoading: false });
        }
        case actions.clearPostInfo.TRIGGER: {
            return __assign(__assign({}, state), { postInfo: {}, postComments: [], moreArticlesLoading: true });
        }
        case actions.getPostComments.TRIGGER: {
            return __assign(__assign({}, state), { commentsLoading: true });
        }
        case actions.getPostComments.SUCCESS: {
            return __assign(__assign({}, state), { commentPages: payload.totalPages, postComments: payload.comments, commentsLoading: false });
        }
        case actions.sendPostComment.TRIGGER: {
            return __assign(__assign({}, state), { commentsLoading: true, commentErrorMessage: false, commentSuccessMessage: false });
        }
        case actions.sendPostComment.SUCCESS: {
            return __assign(__assign({}, state), { commentsLoading: false, commentErrorMessage: false, commentSuccessMessage: true });
        }
        case actions.sendPostComment.FAILURE: {
            console.log(payload.data.message);
            return __assign(__assign({}, state), { commentsLoading: false, commentErrorCode: payload.data.code, commentErrorMessage: payload.data.message });
        }
        case actions.sendPostRating.TRIGGER: {
            return __assign(__assign({}, state), { ratingLoading: true, ratingSending: true });
        }
        case actions.sendPostRating.SUCCESS: {
            var postRating = state.postRating;
            var rated = payload[0].status.rated;
            var newPostRating = __assign(__assign({}, postRating), { user_rated: rated });
            return __assign(__assign({}, state), { postRating: newPostRating, ratingSending: false });
        }
        case actions.sendPostRating.FAILURE: {
            return __assign(__assign({}, state), { ratingLoading: false, ratingSending: false });
        }
        case actions.getPostRating.TRIGGER: {
            return __assign(__assign({}, state), { ratingLoading: true });
        }
        case actions.getPostRating.SUCCESS: {
            return __assign(__assign({}, state), { postRating: payload[0], ratingLoading: false });
        }
        case actions.replyToPostComment.TRIGGER: {
            return __assign(__assign({}, state), { commentsLoading: true });
        }
        case actions.replyToPostComment.SUCCESS: {
            return __assign(__assign({}, state), { commentsLoading: false });
        }
        case actions.replyToPostComment.FAILURE: {
            return __assign(__assign({}, state), { commentsLoading: false });
        }
        case actions.updateToPostComment.TRIGGER: {
            return __assign(__assign({}, state), { commentsLoading: true });
        }
        case actions.updateToPostComment.SUCCESS: {
            return __assign(__assign({}, state), { commentsLoading: true });
        }
        case actions.updateToPostComment.FAILURE: {
            return __assign(__assign({}, state), { commentsLoading: false });
        }
        case actions.reportToPostComment.TRIGGER: {
            return __assign(__assign({}, state), { commentsLoading: false });
        }
        case actions.reportToPostComment.SUCCESS: {
            return __assign(__assign({}, state), { commentsLoading: false });
        }
        case actions.upVoteToPostComment.TRIGGER: {
            return __assign(__assign({}, state), { commentsLoading: false });
        }
        case actions.setCuratePost.TRIGGER: {
            return __assign(__assign({}, state), { curateLoading: true });
        }
        case actions.setCuratePost.SUCCESS: {
            return __assign(__assign({}, state), { curateLoading: false });
        }
        case actions.setCuratePost.FAILURE: {
            return __assign(__assign({}, state), { curateLoading: false });
        }
        case actions.resetCommentSuccess.TRIGGER: {
            return __assign(__assign({}, state), { commentSuccessMessage: false });
        }
        case actions.resetCommentSuccess.SUCCESS: {
            return __assign(__assign({}, state), { commentSuccessMessage: false });
        }
        default:
            return state;
    }
}
exports.default = reducer;
