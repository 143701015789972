"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanSetEosAccountMessage = exports.setEosAccount = exports.getRefId = exports.updateAvatar = exports.updateUser = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
exports.updateUser = (0, redux_saga_routines_1.createRoutine)('settings/UPDATE_USER');
exports.updateAvatar = (0, redux_saga_routines_1.createRoutine)('settings/UPDATE_AVATAR');
exports.getRefId = (0, redux_saga_routines_1.createRoutine)('settings/GET_REF_ID');
//export const follow = createRoutine('following/FOLLOW');
//export const unfollow = createRoutine('following/UNFOLLOW');
exports.setEosAccount = (0, redux_saga_routines_1.createRoutine)('settings/SET_EOS_ACCOUNT');
exports.cleanSetEosAccountMessage = (0, redux_saga_routines_1.createRoutine)('settings/CLEAN_MESSAGES');
