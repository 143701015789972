"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HomePageTranslation = [
    {
        en: {
            translations: {
                homeHeaderOne: "Magic Made for <span>Fans</span>",
                homeHeaderTwo: "Join a Superfan Club Powered by Web3 <br /> and Get Unique Benefits & Experiences.",
                homeHeaderThree: "powered by <span>NFT MultiPasses</span>",
                homeButton1: "Get Started",
                homeButton2: "Create Fan club",
                homeMultiPasses: {
                    title: "NFT MultiPasses give you <br /> <span>exclusive benefits</span>",
                    list: [
                        {
                            title: "Exclusive Content",
                            sub_title: "Join VIP Fan Clubs to get unreleased content",
                            url: "/creator/trip",
                            image: "https://loop-markets.directus.app/assets/48a3eaed-7bb4-4b0d-ba3c-f7cdb60aa594",
                            bg: "/img/mpbg1.png",
                        },
                        {
                            title: "Giveaways & Comps",
                            sub_title: "Participate in games and comps and get real-world prizes",
                            url: "/creator/camilasierra",
                            image: "https://loop-markets.directus.app/assets/2f2836ba-47e1-4853-974c-7e3d27e9fecd",
                            bg: "/img/mpbg2.png",
                        },
                        {
                            title: "Products & Merch",
                            sub_title: "Redeem NFTs for physical goods",
                            url: "/creator/camilopulgarin",
                            image: "https://loop-markets.directus.app/assets/2837d60c-61a2-4770-b76a-e401dcfc19b0",
                            bg: "/img/mpbg3.png",
                        },
                        {
                            title: "Private Communities",
                            sub_title: "Get VIP Memberships to interact with artists and creators",
                            url: "/creator/trip",
                            image: "https://loop-markets.directus.app/assets/45d9eb03-efef-4bc3-bc61-0462479ba81c",
                            bg: "/img/mpbg4.png",
                        },
                        {
                            title: "Limited Offers",
                            sub_title: "Benefit from special offers on products, services or events",
                            url: "/creator/camilasierra",
                            image: "https://loop-markets.directus.app/assets/e2a69131-5814-4830-92c6-2746729a807b",
                            bg: "/img/mpbg5.png",
                        },
                        {
                            title: "Crowdfunding",
                            sub_title: "Support and invest in artists and get rewarded as they grow",
                            url: "/creator/buenavidabeach",
                            image: "https://loop-markets.directus.app/assets/1881d939-1b5d-472c-8cab-5e3756503fa9",
                            bg: "/img/mpbg6.png",
                        },
                        {
                            title: "Digital Tickets",
                            sub_title: "Get priority tickets for events and concerts",
                            url: "/creator/andrewrod",
                            image: "https://loop-markets.directus.app/assets/02b2b836-65af-448b-b750-a5818452f0b4",
                            bg: "/img/mpbg7.png",
                        },
                        {
                            title: "Metaverse Experiences",
                            sub_title: "Live unimaginable experiences with artists and brands in the Metaverse",
                            url: "/creator/starvibes",
                            image: "https://loop-markets.directus.app/assets/f67c2f37-81bc-49bb-8bbb-26c3d4d5ba3c",
                            bg: "/img/mpbg8.png",
                        },
                    ],
                },
                homeDiscover: {
                    headline: "discover",
                    rotatorWords: ["Creators", "Musicians", "Influencers",],
                    subHeadline: "Music, photos, videos, tickets, products, and other exclusives from",
                },
                homeUpcomingReleases: {
                    title: "See <span>live</span> and <span>upcoming</span> NFT Collections",
                    list: [
                        {
                            title: ". . .",
                            url: "/creator/xpecta",
                            badge: "live",
                            image: "https://loop-markets.directus.app/assets/a59037b0-3a38-43be-9df0-39e047bac535",
                        },
                        {
                            title: ". . .",
                            url: "/creator/buenavidabeach",
                            badge: "live",
                            image: "https://loop-markets.directus.app/assets/0c24db19-67ab-4d26-bc0c-cbd0e848651f",
                        },
                        {
                            title: ". . .",
                            url: "/creator/camilopulgarin",
                            badge: "live",
                            image: "https://loop-markets.directus.app/assets/7267cf27-a9c7-46da-b017-5cd903fbcf17",
                        },
                        /* {
                          title: ". . .",
                          url: "/creator/trip",
                          image:
                            "https://loop-markets.directus.app/assets/3af28cec-995f-431c-a7e0-167db0e94b52",
                        }, */
                    ],
                },
                homeMembershipClubs: {
                    title: "The Magic of <br /> Loop <span>NFT-powered</span> <br /> VIP Membership Clubs",
                    itemOne: "<p><b>Simple and Easy</b><br /><span>VIP Membership Clubs work like any social media, <br /> while you invest in and support artists and creators</span></p>",
                    itemTwo: "<p><b>MultiPasses = Multiple benefits</b><br /><span>The Digital Value of tickets, <br /> special offers and exclusive content, magicly made for you</span></p>",
                    itemThree: "<p><b>Real Digital Value</b><br /><span>A dedicated Club for Fans who truly cares. This is the future of digital <br /> goods where assets grow in value overtime, as the artist grows</span></p>",
                    buttonText: "Join fan clubs",
                },
                homeSpotlight: {
                    img: "https://loop-markets.directus.app/assets/a76d9f7e-5ef5-4f45-b3c1-6f89765bc9f5",
                },
                homeHowItWorks: {
                    title: "How it works",
                    list: [
                        {
                            title: "Create a profile to <br /> <b>follow Artists & Creators</b>",
                            url: "#",
                            image: "/img/378617.svg",
                            isIcon: true,
                        },
                        {
                            title: "Browse to <b>Join Fan Clubs</b>",
                            url: "#",
                            image: "/img/378618.svg",
                            isIcon: true,
                        },
                        {
                            title: "<b>Subscribe to Artists and Creators</b> for <br /> Exclusive Content and Benefits",
                            url: "#",
                            image: "/img/378620.svg",
                            isIcon: true,
                        },
                    ],
                },
                homeNftsForAll: {
                    title: "NFTs are for all",
                    subTitle: "(Not just for experts)",
                    list: [
                        {
                            title: "",
                            url: "#",
                            image: "https://loop-markets.directus.app/assets/40bd488a-3c49-4af1-9d81-cc33dfd72b43",
                            isIcon: false,
                        },
                        {
                            title: "",
                            url: "#",
                            image: "https://loop-markets.directus.app/assets/3857b2a5-501e-4ffb-992c-1e02e1df5877",
                            isIcon: false,
                        },
                        {
                            title: "",
                            url: "#",
                            image: "https://loop-markets.directus.app/assets/c37dba06-bca4-449d-bb76-431ddbab74a8",
                            isIcon: false,
                        },
                    ],
                },
                homeCreatorPromo: {
                    title: "Are you a Creator <br /> or Influencer?",
                    subText: "Hundreds of creators have started taking ownership <br /> of their creativity and monetizing their content",
                    buttonText: "Learn more",
                },
            },
        },
        es: {
            translations: {
                homeHeaderOne: "Magia hecha para <span>Fans</span>",
                homeHeaderTwo: "Únete a una Comunidad de Superfans Potenciada por Web3 <br /> y Obtén Beneficios y Experiencias Únicas.",
                homeHeaderThree: "potenciado por <span>MultiPases</span>",
                homeButton1: "Empezar",
                homeButton2: "Crear club de fans",
                homeMultiPasses: {
                    title: "Los MultiPases te brindan <br /> <span>beneficios exclusivos</span>",
                    list: [
                        {
                            title: "Contenido Exclusivo",
                            sub_title: "Únete a Clubes VIP de Fans para obtener contenido inédito",
                            url: "/creador/viaje",
                            image: "https://loop-markets.directus.app/assets/48a3eaed-7bb4-4b0d-ba3c-f7cdb60aa594",
                            bg: "/img/mpbg1.png",
                        },
                        {
                            title: "Regalos y Concursos",
                            sub_title: "Participa en juegos y concursos y gana premios del mundo real",
                            url: "/creador/camilasierra",
                            image: "https://loop-markets.directus.app/assets/2f2836ba-47e1-4853-974c-7e3d27e9fecd",
                            bg: "/img/mpbg2.png",
                        },
                        {
                            title: "Productos y Merch",
                            sub_title: "Cámbialos por productos físicos",
                            url: "/creador/camilopulgarin",
                            image: "https://loop-markets.directus.app/assets/2837d60c-61a2-4770-b76a-e401dcfc19b0",
                            bg: "/img/mpbg3.png",
                        },
                        {
                            title: "Comunidades Privadas",
                            sub_title: "Obtén Membresías VIP para interactuar con artistas y creadores",
                            url: "/creador/viaje",
                            image: "https://loop-markets.directus.app/assets/45d9eb03-efef-4bc3-bc61-0462479ba81c",
                            bg: "/img/mpbg4.png",
                        },
                        {
                            title: "Ofertas Limitadas",
                            sub_title: "Benefíciate de ofertas especiales en productos, servicios o eventos",
                            url: "/creador/camilasierra",
                            image: "https://loop-markets.directus.app/assets/557daa3e-7ed8-446c-970e-3b87e7b3e09e",
                            bg: "/img/mpbg5.png",
                        },
                        {
                            title: "Crowdfunding",
                            sub_title: "Apoya e invierte en artistas y obtén recompensas a medida que crecen",
                            url: "/creador/buenavidabeach",
                            image: "https://loop-markets.directus.app/assets/1881d939-1b5d-472c-8cab-5e3756503fa9",
                            bg: "/img/mpbg6.png",
                        },
                        {
                            title: "Entradas Digitales",
                            sub_title: "Obtén entradas prioritarias para eventos y conciertos",
                            url: "/creador/andrewrod",
                            image: "https://loop-markets.directus.app/assets/e4d04c69-72eb-485d-b8c0-cf37e9559c54",
                            bg: "/img/mpbg7.png",
                        },
                        {
                            title: "Experiencias Metaverse",
                            sub_title: "Vive experiencias inimaginables con artistas y marcas en el Metaverso",
                            url: "/creador/starvibes",
                            image: "https://loop-markets.directus.app/assets/f67c2f37-81bc-49bb-8bbb-26c3d4d5ba3c",
                            bg: "/img/mpbg8.png",
                        },
                    ],
                },
                homeDiscover: {
                    headline: "descubre",
                    rotatorWords: ["Creadores", "Músicos", "Influencers"],
                    subHeadline: "Música, fotos, videos, entradas, productos y otros exclusivos de",
                },
                homeUpcomingReleases: {
                    title: "Ver Colecciones <span>en vivo</span> y <span>próximas</span>",
                    list: [
                        {
                            title: ". . .",
                            url: "/creator/xpecta",
                            badge: "live",
                            image: "https://loop-markets.directus.app/assets/a59037b0-3a38-43be-9df0-39e047bac535",
                        },
                        {
                            title: ". . .",
                            url: "/creator/buenavidabeach",
                            badge: "live",
                            image: "https://loop-markets.directus.app/assets/0c24db19-67ab-4d26-bc0c-cbd0e848651f",
                        },
                        {
                            title: ". . .",
                            url: "/creator/camilopulgarin",
                            badge: "live",
                            image: "https://loop-markets.directus.app/assets/7267cf27-a9c7-46da-b017-5cd903fbcf17",
                        },
                        /* {
                          title: ". . .",
                          url: "/creator/trip",
                          image:
                            "https://loop-markets.directus.app/assets/3af28cec-995f-431c-a7e0-167db0e94b52",
                        }, */
                    ],
                },
                homeMembershipClubs: {
                    title: "La Magia de <br /> Loop <span>con energía de</span> <br /> Clubes VIP",
                    itemOne: "<p><b>Sencillo y Fácil</b><br /><span>Los Clubes VIP de Membresía funcionan como cualquier red social, <br /> mientras inviertes y apoyas a artistas y creadores</span></p>",
                    itemTwo: "<p><b>MultiPases = Múltiples beneficios</b><br /><span>El Valor Digital de entradas, <br /> ofertas especiales y contenido exclusivo, creado mágicamente para ti</span></p>",
                    itemThree: "<p><b>Valor Digital Real</b><br /><span>Un Club dedicado para Fans que realmente se preocupan. Este es el futuro de los bienes digitales donde los activos crecen en valor con el tiempo, a medida que el artista crece</span></p>",
                    buttonText: "Únete a los clubes de fans",
                },
                homeSpotlight: {
                    img: "https://loop-markets.directus.app/assets/c8a0eea4-d169-4897-8473-147633dd54ca",
                },
                homeHowItWorks: {
                    title: "Cómo funciona",
                    list: [
                        {
                            title: "Crea un perfil para <br /> <b>seguir a Artistas y Creadores</b>",
                            url: "#",
                            image: "/img/378617.svg",
                            isIcon: true,
                        },
                        {
                            title: "Explora para <b>Unirte a Clubes de Fans</b>",
                            url: "#",
                            image: "/img/378618.svg",
                            isIcon: true,
                        },
                        {
                            title: "<b>Suscríbete a Artistas y Creadores</b> para <br /> Contenido Exclusivo y Beneficios",
                            url: "#",
                            image: "/img/378620.svg",
                            isIcon: true,
                        },
                    ],
                },
                homeNftsForAll: {
                    title: "Web3 es para todos",
                    subTitle: "(No solo para expertos)",
                    list: [
                        {
                            title: "",
                            url: "#",
                            image: "https://loop-markets.directus.app/assets/40bd488a-3c49-4af1-9d81-cc33dfd72b43",
                            isIcon: false,
                        },
                        {
                            title: "",
                            url: "#",
                            image: "https://loop-markets.directus.app/assets/3857b2a5-501e-4ffb-992c-1e02e1df5877",
                            isIcon: false,
                        },
                        {
                            title: "",
                            url: "#",
                            image: "https://loop-markets.directus.app/assets/c37dba06-bca4-449d-bb76-431ddbab74a8",
                            isIcon: false,
                        },
                    ],
                },
                homeCreatorPromo: {
                    title: "¿Eres un Creador <br /> o Influencer?",
                    subText: "Cientos de creadores han comenzado a tomar control de su creatividad y monetizar su contenido",
                    buttonText: "Aprende más",
                },
            },
        },
    },
];
exports.default = HomePageTranslation;
