"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GetStartedFormTranslation = [
    {
        en: {
            translations: {
                formTitle: "Launch your own collection!",
                formSubTitle: "Send us your information to provide you with advice on how to launch your collections with Loop",
                formRequiredMessage: "All fields are required",
                formAristType: "I am ...",
                formHelpList: "How can we help you?",
                formAchieve: "What do you want to achieve?",
                formMainSocial: "Your main social network",
                formSend: "Send",
                formSentMessage: "Now check your email to confirm your subscription and Download the PDF Guide."
            },
        },
        es: {
            translations: {
                formTitle: "Lanza tu propia colección!",
                formSubTitle: "Envíanos tu información para brindarte asesoría sobre como lanzar tus colecciones con Loop",
                formRequiredMessage: "All fields are required",
                formAristType: "Soy ...",
                formHelpList: "Cómo podemos ayudarte?",
                formAchieve: "Que quieres lograr?",
                formMainSocial: "Tu principal red social",
                formSend: "Enviar",
                formSentMessage: "Ahora revisa tu correo para confirmar tu suscripción y Descargar la Guía PDF."
            },
        },
    },
];
exports.default = GetStartedFormTranslation;
