"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var editorActions = __importStar(require("./EditorActions"));
var defaultState = {
    articleIsCreated: false,
    sendingArticle: false,
    featuredImage: null,
    mentionSuggestions: [],
    articleDeleted: false,
    articleData: [],
    errorMessage: false,
    isError: false,
    previewArticle: {},
    prevArticle: null,
    is_autosave: false,
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case editorActions.clearErrorRoutine.TRIGGER: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', true);
            return __assign(__assign({}, state), { isError: false, errorMessage: false });
        }
        case editorActions.createArticleRoutine.TRIGGER: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', true);
            state.is_autosave = ((_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.is_autosave) ? (_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.is_autosave : false;
            return __assign(__assign({}, state), { articleData: { is_autosave: state === null || state === void 0 ? void 0 : state.is_autosave }, articleIsCreated: false, sendingArticle: true });
        }
        case editorActions.createArticleRoutine.SUCCESS: {
            // const newState = state
            //   .set('articleIsCreated', true)
            //   .set('sendingArticle', false);
            payload.is_autosave = state === null || state === void 0 ? void 0 : state.is_autosave;
            return __assign(__assign({}, state), { articleData: payload, articleIsCreated: true, sendingArticle: false });
        }
        case editorActions.createArticleRoutine.FAILURE: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', false);
            return __assign(__assign({}, state), { articleIsCreated: false, sendingArticle: false, errorMessage: payload, isError: true });
        }
        case editorActions.updateArticleRoutine.TRIGGER: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', true);
            state.is_autosave = ((_c = payload === null || payload === void 0 ? void 0 : payload.data) === null || _c === void 0 ? void 0 : _c.is_autosave) ? (_d = payload === null || payload === void 0 ? void 0 : payload.data) === null || _d === void 0 ? void 0 : _d.is_autosave : false;
            return __assign(__assign({}, state), { articleIsCreated: false, sendingArticle: true });
        }
        case editorActions.updateArticleRoutine.SUCCESS: {
            // const newState = state
            //   .set('articleIsCreated', true)
            //   .set('sendingArticle', false);
            var passIsArticleCreated = (state === null || state === void 0 ? void 0 : state.is_autosave) ? false : true;
            state.is_autosave = false;
            return __assign(__assign({}, state), { articleIsCreated: passIsArticleCreated, sendingArticle: false });
        }
        case editorActions.updateArticleRoutine.FAILURE: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', false);
            return __assign(__assign({}, state), { articleIsCreated: false, sendingArticle: false, errorMessage: payload, isError: true });
        }
        case editorActions.deleteArticleRoutine.TRIGGER: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', true);
            return __assign(__assign({}, state), { articleDeleted: false });
        }
        case editorActions.deleteArticleRoutine.SUCCESS: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', true);
            return __assign(__assign({}, state), { articleDeleted: true });
        }
        case editorActions.deleteArticleRoutine.FAILURE: {
            // const newState = state
            //   .set('articleIsCreated', false)
            //   .set('sendingArticle', true);
            return __assign(__assign({}, state), { articleDeleted: false, errorMessage: payload, isError: true });
        }
        case editorActions.uploadFeaturedImageRoutine.TRIGGER: {
            // const newState = state.set('feauturedImageLoading', true);
            return __assign(__assign({}, state), { feauturedImageLoading: true });
        }
        case editorActions.uploadFeaturedImageRoutine.SUCCESS: {
            // const newState = state
            //   .set('feauturedImageLoading', false)
            //   .set('featuredImage', payload);
            return __assign(__assign({}, state), { feauturedImageLoading: false, featuredImage: payload });
        }
        case editorActions.uploadFeaturedImageRoutine.FAILURE: {
            // const newState = state.set('feauturedImageLoading', false);
            return __assign(__assign({}, state), { feauturedImageLoading: true });
        }
        case editorActions.articleIsCreatedRoutine.TRIGGER: {
            // const newState = state.set('articleIsCreated', false);
            return __assign(__assign({}, state), { articleIsCreated: false, articleDeleted: false });
        }
        case editorActions.getMentionSuggestions.SUCCESS: {
            // const newState = state.set('mentionSuggestions', payload);
            return __assign(__assign({}, state), { mentionSuggestions: payload });
        }
        case editorActions.previewArticleRoutine.TRIGGER: {
            return __assign(__assign({}, state), { previewArticle: payload, prevArticle: null });
        }
        case editorActions.previewArticleRoutine.SUCCESS: {
            localStorage.setItem('previewArticle', JSON.stringify(payload.payload));
            return __assign(__assign({}, state), { previewArticle: payload.payload, prevArticle: true });
        }
        case editorActions.previewArticleRoutine.FAILURE: {
            return __assign(__assign({}, state), { previewArticle: payload, prevArticle: false });
        }
        case editorActions.getPreviewArticleRoutine.TRIGGER: {
            return __assign(__assign({}, state), { previewArticle: {}, prevArticle: null });
        }
        case editorActions.getPreviewArticleRoutine.SUCCESS: {
            var previewData = localStorage.getItem('previewArticle');
            return __assign(__assign({}, state), { previewArticle: previewData ? JSON.parse(previewData) : {}, prevArticle: previewData ? true : false });
        }
        case editorActions.getPreviewArticleRoutine.FAILURE: {
            return __assign(__assign({}, state), { previewArticle: {}, prevArticle: false });
        }
        case editorActions.clearPreviewArticleRoutine.TRIGGER: {
            console.log("Got it reached");
            localStorage.removeItem('previewArticle');
            return __assign(__assign({}, state), { previewArticle: {}, prevArticle: null });
        }
        default:
            return state;
    }
}
exports.default = reducer;
