"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unfollowWatcher = exports.followWatcher = exports.getFollowingWatch = exports.getFollowersWatch = exports.followingWatchers = void 0;
var effects_1 = require("redux-saga/effects");
var actions = __importStar(require("./FollowingActions"));
var api = __importStar(require("./FollowingApi"));
exports.followingWatchers = [
    getFollowersWatch,
    getFollowingWatch,
    followWatcher,
    unfollowWatcher
];
function getFollowersWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getFollowers.TRIGGER, getFollowers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getFollowersWatch = getFollowersWatch;
function getFollowingWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getFollowing.TRIGGER, getFollowing)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getFollowingWatch = getFollowingWatch;
function followWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.follow.TRIGGER, follow)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.followWatcher = followWatcher;
function getFollowers(_a) {
    var serverResponse, proccessed;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getMyFollowers(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                proccessed = serverResponse.data.map(function (el) {
                    return {
                        person: el,
                        disabled: false
                    };
                });
                return [4 /*yield*/, (0, effects_1.put)(actions.getFollowers.success(proccessed))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                console.log('something went wrong ', serverResponse);
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function getFollowing(_a) {
    var serverResponse, proccessed;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getFollowingUsers(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                proccessed = serverResponse.data.map(function (el) { return ({
                    person: el,
                    isLoading: false
                }); });
                return [4 /*yield*/, (0, effects_1.put)(actions.getFollowing.success(proccessed))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                console.log('something went wrong ', serverResponse);
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function follow(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.follow(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'followbyuser',
                    'event_category': 'community',
                    'event_action': 'followbyuser',
                    'event_label': payload.authorName //the article’s author
                });
                return [4 /*yield*/, (0, effects_1.put)(actions.follow.success({ getstreamResponse: serverResponse.data, index: payload.index }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('unable to follow the user ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.follow.failure({ getstreamResponce: serverResponse, index: payload.index }))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function unfollowWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.unfollow.TRIGGER, unfollow)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.unfollowWatcher = unfollowWatcher;
function unfollow(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.unfollow(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.unfollow.success({ getstreamResponse: serverResponse.data, index: payload.index }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('unable to unfollow the user ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.unfollow.failure({ getstreamResponse: serverResponse, index: payload.index }))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
