"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelFriendshipRequestWatch = exports.rejectFriendshipRequestWatch = exports.acceptFriendshipRequestWatch = exports.requestFriendshipWatch = exports.removeFriendWatch = exports.getRequestsWatch = exports.getFriendsWatch = exports.checkFriendshipWatch = exports.friendsWatchers = void 0;
var effects_1 = require("redux-saga/effects");
var actions = __importStar(require("./FriendsActions"));
var api = __importStar(require("./FriendsApi"));
// import {
//   listoffriends,
//   friendshipsrequestlist
// } from '../../util/mockedFriendshipRequests';
exports.friendsWatchers = [
    getFriendsWatch,
    getRequestsWatch,
    requestFriendshipWatch,
    acceptFriendshipRequestWatch,
    cancelFriendshipRequestWatch,
    rejectFriendshipRequestWatch,
    removeFriendWatch,
    checkFriendshipWatch
];
function checkFriendshipWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.checkFriendshipStatus.TRIGGER, checkFriendshipStatus)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.checkFriendshipWatch = checkFriendshipWatch;
function getFriendsWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getFriends.TRIGGER, getFriends)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getFriendsWatch = getFriendsWatch;
function getRequestsWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getRequests.TRIGGER, getRequests)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getRequestsWatch = getRequestsWatch;
function removeFriendWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.removeFriend.TRIGGER, removeFriend)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.removeFriendWatch = removeFriendWatch;
function requestFriendshipWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.requestFriendship.TRIGGER, requestFriendship)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.requestFriendshipWatch = requestFriendshipWatch;
function acceptFriendshipRequestWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.acceptFriendshipRequest.TRIGGER, acceptFriendshipRequest)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.acceptFriendshipRequestWatch = acceptFriendshipRequestWatch;
function rejectFriendshipRequestWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.rejectFriendshipRequest.TRIGGER, rejectFriendshipRequest)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.rejectFriendshipRequestWatch = rejectFriendshipRequestWatch;
function cancelFriendshipRequestWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeEvery)(actions.cancelFriendshipRequest.TRIGGER, cancelFriendshipRequest)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.cancelFriendshipRequestWatch = cancelFriendshipRequestWatch;
function cancelFriendshipRequest(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.cancelFriendshipRequest(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.cancelFriendshipRequest.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.cancelFriendshipRequest.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function checkFriendshipStatus(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.checkFriendshipStatus(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.checkFriendshipStatus.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.checkFriendshipStatus.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function rejectFriendshipRequest(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.rejectFriendshipRequest(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.rejectFriendshipRequest.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.rejectFriendshipRequest.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function acceptFriendshipRequest(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.acceptFriendshipRequest(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.acceptFriendshipRequest.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.acceptFriendshipRequest.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function requestFriendship(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.createFriendshipRequest(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.requestFriendship.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.requestFriendship.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getFriends(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.listFriends(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getFriends.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.getFriends.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getRequests(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.listFriendshipRequests(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getRequests.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.getRequests.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function removeFriend(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.removeFriend(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.removeFriend.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                console.log('something went wrong ', serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.removeFriend.failure())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
