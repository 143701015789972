"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FanClubTranslation = [
    {
        en: {
            translations: {
                fanClubHeading: "Join VIP Clubs to access exclusive collections and content",
            },
        },
        es: {
            translations: {
                fanClubHeading: "Únete a los VIP Clubs para acceder a colecciones y contenido exclusivos",
            },
        },
    },
];
exports.default = FanClubTranslation;
