"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearPreviewArticleRoutine = exports.getPreviewArticleRoutine = exports.previewArticleRoutine = exports.clearErrorRoutine = exports.getMentionSuggestions = exports.deleteArticleRoutine = exports.updateArticleRoutine = exports.articleIsCreatedRoutine = exports.uploadFeaturedImageRoutine = exports.createArticleRoutine = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
exports.createArticleRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/CREATE_ARTICLE');
exports.uploadFeaturedImageRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/UPLOAD_FEATURED_IMAGE');
exports.articleIsCreatedRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/ARTICLE_IS_CREATED');
exports.updateArticleRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/UPDATE_ARTICLE');
exports.deleteArticleRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/DELETE_ARTICLE');
exports.getMentionSuggestions = (0, redux_saga_routines_1.createRoutine)('EDITOR/GET_MENTION_SUGGESTIONS');
exports.clearErrorRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/CLEAR_ERROR');
exports.previewArticleRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/PREVIEW_ARTICLE');
exports.getPreviewArticleRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/GET_PREVIEW_ARTICLE');
exports.clearPreviewArticleRoutine = (0, redux_saga_routines_1.createRoutine)('EDITOR/RREMOVE_PREVIEW_ARTICLE');
