"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkFriendshipStatus = exports.removeFriend = exports.acceptFriendshipRequest = exports.cancelFriendshipRequest = exports.rejectFriendshipRequest = exports.requestFriendship = exports.getRequests = exports.getFriends = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
exports.getFriends = (0, redux_saga_routines_1.createRoutine)('friends/GET_FRIENDS');
exports.getRequests = (0, redux_saga_routines_1.createRoutine)('friends/GET_REQUESTS');
exports.requestFriendship = (0, redux_saga_routines_1.createRoutine)('friends/REQUEST_FRIENDSHIP');
exports.rejectFriendshipRequest = (0, redux_saga_routines_1.createRoutine)('friends/REJECT_FRIENDSHIP_REQUEST');
exports.cancelFriendshipRequest = (0, redux_saga_routines_1.createRoutine)('friends/CANCEL_FRIENDSHIP_REQUEST');
exports.acceptFriendshipRequest = (0, redux_saga_routines_1.createRoutine)('friends/ACCEPT_FRIENDSHIP_REQUEST');
exports.removeFriend = (0, redux_saga_routines_1.createRoutine)('friends/REMOVE_FRIEND');
exports.checkFriendshipStatus = (0, redux_saga_routines_1.createRoutine)('friends/CHECK_FRIENDSHIP_STATUS');
