"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions = __importStar(require("./SidebarActions"));
var defaultState = {
    visible: false,
    mode: null
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case actions.openSidebar.TRIGGER: {
            return __assign(__assign({}, state), { visible: true, mode: payload });
        }
        case actions.closeSidebar.TRIGGER: {
            return __assign(__assign({}, state), { visible: false, mode: null });
        }
        case actions.toggleSidebar.TRIGGER: {
            var modeChanged = state.mode !== payload;
            return __assign(__assign({}, state), { visible: modeChanged, mode: modeChanged ? payload : null });
        }
        default:
            return state;
    }
}
exports.default = reducer;
