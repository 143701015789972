"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.datalayer = void 0;
var datalayer = function (event, event_action, event_label) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: event,
        event_category: "user_action",
        event_action: event_action,
        event_label: event_label, // Value of action
    });
};
exports.datalayer = datalayer;
