"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions = __importStar(require("./SocialFeedActions"));
var defaultState = {
    loading: false,
    postSending: false,
    postSuccessfullSended: false,
    likeSending: false,
    commentSending: false,
    expandedPost: null,
    posts: [],
    latestId: null,
    comments: [],
    loadingComments: false,
    userHelper: []
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case actions.getSocialFeed.TRIGGER: {
            payload.latestId = state.latestId;
            return __assign(__assign({}, state), { loading: true });
        }
        case actions.getSocialFeed.SUCCESS: {
            var posts = state.posts;
            var newPosts = payload.posts;
            if (!state.latestId || !payload.more)
                posts = newPosts;
            if (payload.more)
                posts = __spreadArray(__spreadArray([], posts, true), newPosts, true);
            var latestId = newPosts.length !== 0
                ? newPosts[newPosts.length - 1].id
                : state.latestId;
            return __assign(__assign({}, state), { posts: posts, loading: false, latestId: latestId });
        }
        case actions.getSocialFeed.FAILURE: {
            return __assign(__assign({}, state), { loading: false, latestId: null });
        }
        case actions.createSocialPost.TRIGGER: {
            return __assign(__assign({}, state), { postSending: true, postSuccessfullSended: false });
        }
        case actions.createSocialPost.SUCCESS: {
            return __assign(__assign({}, state), { postSending: false, postSuccessfullSended: true, latestId: null });
        }
        case actions.createSocialPost.FAILURE: {
            return __assign(__assign({}, state), { postSending: false, postSuccessfullSended: false });
        }
        case actions.cleanSocialFeed.TRIGGER:
        case actions.cleanSocialFeed.SUCCESS: {
            return defaultState;
        }
        case actions.like.TRIGGER: {
            return __assign(__assign({}, state), { likeSending: true });
        }
        case actions.like.SUCCESS: {
            return __assign(__assign({}, state), { likeSending: false });
        }
        case actions.like.FAILURE: {
            return __assign(__assign({}, state), { likeSending: false });
        }
        case actions.createSocialComment.TRIGGER: {
            return __assign(__assign({}, state), { commentSending: true });
        }
        case actions.createSocialComment.SUCCESS: {
            return __assign(__assign({}, state), { commentSending: false });
        }
        case actions.createSocialComment.FAILURE: {
            return __assign(__assign({}, state), { commentSending: false });
        }
        case actions.deleteReaction.TRIGGER: {
            return __assign(__assign({}, state), { likeSending: true });
        }
        case actions.deleteReaction.SUCCESS: {
            return __assign(__assign({}, state), { likeSending: false });
        }
        case actions.deleteReaction.FAILURE: {
            return __assign(__assign({}, state), { likeSending: false });
        }
        case actions.expandPost.TRIGGER: {
            return __assign(__assign({}, state), { loadingComments: true, expandedPost: payload.activityId });
        }
        case actions.expandPost.SUCCESS: {
            return __assign(__assign({}, state), { loadingComments: false, comments: payload });
        }
        case actions.expandPost.FAILURE: {
            return __assign(__assign({}, state), { loadingComments: false });
        }
        case actions.rollPostBack.TRIGGER: {
            return __assign(__assign({}, state), { comments: [], expandedPost: null });
        }
        case actions.getUserHelperData.TRIGGER: {
            return __assign(__assign({}, state), { isLoadingDisplayData: true });
        }
        case actions.getUserHelperData.SUCCESS: {
            return __assign(__assign({}, state), { isLoadingDisplayData: false, userHelper: payload });
        }
        default:
            return state;
    }
}
exports.default = reducer;
