"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
function Modal(props) {
    var open = props.open, close = props.close, size = props.size, title = props.title, showIcon = props.showIcon, showBtn = props.showBtn, btnText = props.btnText, btnAction = props.btnAction, btnDisabled = props.btnDisabled, btnLoading = props.btnLoading, content = props.content, customClass = props.customClass;
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal, __assign({ size: size, open: open, className: "mainModal ".concat(customClass), closeIcon: true, onClose: close }, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Header, __assign({ icon: true, hidden: !showIcon }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "check circle" }) })), (0, jsx_runtime_1.jsx)("h3", __assign({ className: "modalTitle", hidden: !title }, { children: title })), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Modal.Content, { children: (0, jsx_runtime_1.jsx)("div", { dangerouslySetInnerHTML: { __html: content } }) }), showBtn && ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ disabled: btnDisabled, loading: btnLoading, onClick: function () { return btnAction(); }, className: "modalButton" }, { children: btnText })))] })));
}
exports.default = Modal;
