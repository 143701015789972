"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendPostRatingWatch = exports.sendCuratePostWatch = exports.sendUpVoteToPostCommentWatch = exports.successfullReportSendPostWatch = exports.sendReportToPostCommentWatch = exports.failureUpdateSendPostWatch = exports.successfullUpdateSendPostWatch = exports.sendUpdateToPostCommentWatch = exports.successfullReplySendPostWatch = exports.sendReplyToPostCommentWatch = exports.getPostRatingWatch = exports.successfullCommentSendPostWatch = exports.sendPostCommentWatch = exports.getPostCommentsWatch = exports.getAuthorPostWatch = exports.sendPrerenderWatch = exports.getPrerenderWatch = exports.getReportArticleWatch = exports.getPostInfoWatch = exports.postWatchers = void 0;
var effects_1 = require("redux-saga/effects");
var actions = __importStar(require("./postActions"));
var api = __importStar(require("./postApi"));
exports.postWatchers = [
    getPostInfoWatch,
    getPostCommentsWatch,
    sendPostCommentWatch,
    successfullCommentSendPostWatch,
    successfullReplySendPostWatch,
    sendReplyToPostCommentWatch,
    sendUpdateToPostCommentWatch,
    successfullUpdateSendPostWatch,
    failureUpdateSendPostWatch,
    sendReportToPostCommentWatch,
    successfullReportSendPostWatch,
    sendUpVoteToPostCommentWatch,
    getPostRatingWatch,
    sendPostRatingWatch,
    getAuthorPostWatch,
    getPrerenderWatch,
    sendPrerenderWatch,
    getReportArticleWatch,
    sendCuratePostWatch,
];
function getPostInfoWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getPostInfo.TRIGGER, getPostInfo)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getPostInfoWatch = getPostInfoWatch;
function getReportArticleWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.reportArticleRoutine.TRIGGER, reportArticle)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getReportArticleWatch = getReportArticleWatch;
function getPrerenderWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getPrerenderRoutine.TRIGGER, getPrerender)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getPrerenderWatch = getPrerenderWatch;
function sendPrerenderWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.sendPrerenderRoutine.TRIGGER, sendPrerenderAction)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendPrerenderWatch = sendPrerenderWatch;
function getAuthorPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getAuthorPost.TRIGGER, getAuthorPost)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getAuthorPostWatch = getAuthorPostWatch;
function getPostCommentsWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getPostComments.TRIGGER, getPostComments)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getPostCommentsWatch = getPostCommentsWatch;
function sendPostCommentWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.sendPostComment.TRIGGER, sendPostComment)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendPostCommentWatch = sendPostCommentWatch;
function successfullCommentSendPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.sendPostComment.SUCCESS, getPostComments)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.successfullCommentSendPostWatch = successfullCommentSendPostWatch;
function getPostRatingWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.getPostRating.TRIGGER, getPostRating)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.getPostRatingWatch = getPostRatingWatch;
function sendReplyToPostCommentWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.replyToPostComment.TRIGGER, sendReplyToPostComment)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendReplyToPostCommentWatch = sendReplyToPostCommentWatch;
function successfullReplySendPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.replyToPostComment.SUCCESS, getPostComments)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.successfullReplySendPostWatch = successfullReplySendPostWatch;
function sendUpdateToPostCommentWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.updateToPostComment.TRIGGER, sendUpdateToPostComment)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendUpdateToPostCommentWatch = sendUpdateToPostCommentWatch;
function successfullUpdateSendPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.updateToPostComment.SUCCESS, getPostComments)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.successfullUpdateSendPostWatch = successfullUpdateSendPostWatch;
function failureUpdateSendPostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.updateToPostComment.FAILURE, getPostComments)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.failureUpdateSendPostWatch = failureUpdateSendPostWatch;
function sendReportToPostCommentWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.reportToPostComment.TRIGGER, sendReportToPostComment)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendReportToPostCommentWatch = sendReportToPostCommentWatch;
function successfullReportSendPostWatch() {
    return __generator(this, function (_a) {
        return [2 /*return*/];
    });
}
exports.successfullReportSendPostWatch = successfullReportSendPostWatch;
function sendUpVoteToPostCommentWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.upVoteToPostComment.TRIGGER, sendUpVoteToPostComment)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendUpVoteToPostCommentWatch = sendUpVoteToPostCommentWatch;
function sendCuratePostWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.setCuratePost.TRIGGER, sendCuratePost)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendCuratePostWatch = sendCuratePostWatch;
function sendPostRatingWatch() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(actions.sendPostRating.TRIGGER, sendPostRating)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.sendPostRatingWatch = sendPostRatingWatch;
function getPostInfo(_a) {
    var id, serverResponse, _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                id = payload.id;
                if (!parseInt(id)) return [3 /*break*/, 2];
                return [4 /*yield*/, api.getPostInfo(payload)];
            case 1:
                serverResponse = _c.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, api.getPostInfoBySlug(payload)];
            case 3:
                serverResponse = _c.sent();
                _c.label = 4;
            case 4:
                if (!serverResponse.data) return [3 /*break*/, 9];
                if (!Array.isArray(serverResponse.data)) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.put)(actions.getPostInfo.success(serverResponse.data[0]))];
            case 5:
                _b = _c.sent();
                return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, (0, effects_1.put)(actions.getPostInfo.success(serverResponse.data))];
            case 7:
                _b = _c.sent();
                _c.label = 8;
            case 8:
                _b;
                return [3 /*break*/, 11];
            case 9: return [4 /*yield*/, (0, effects_1.put)(actions.getPostInfo.failure(serverResponse))];
            case 10:
                _c.sent();
                _c.label = 11;
            case 11: return [2 /*return*/];
        }
    });
}
function reportArticle(payload) {
    var serverResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api.reportArticle(payload)];
            case 1:
                serverResponse = _a.sent();
                if (!(serverResponse.status == 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.reportArticleRoutine.success(serverResponse.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.reportArticleRoutine.failure(serverResponse))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getPrerender(data) {
    var url, draft, serverResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "https://www.loop.markets/".concat(data.payload, "/");
                console.log(url);
                draft = data.payload == '';
                if (!!draft) return [3 /*break*/, 6];
                return [4 /*yield*/, api.getPrerender(url)];
            case 1:
                serverResponse = _a.sent();
                if (!serverResponse.data[0]) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getPrerenderRoutine.success(serverResponse.data[0]))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.getPrerenderRoutine.failure(serverResponse))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, (0, effects_1.put)(actions.getPrerenderRoutine.failure(serverResponse))];
            case 7:
                _a.sent();
                _a.label = 8;
            case 8: return [2 /*return*/];
        }
    });
}
function sendPrerenderAction(data) {
    var url, serverResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "https://www.loop.markets/".concat(data.payload, "/");
                console.log(url);
                return [4 /*yield*/, api.sendPrerender(url)];
            case 1:
                serverResponse = _a.sent();
                if (!serverResponse) return [3 /*break*/, 3];
                console.log(serverResponse);
                return [4 /*yield*/, (0, effects_1.put)(actions.sendPrerenderRoutine.success(serverResponse.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.sendPrerenderRoutine.failure(serverResponse))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getAuthorPost(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getAuthorPost(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getAuthorPost.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.getAuthorPost.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getPostComments(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getPostComments(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getPostComments.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.getPostComments.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendPostComment(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log('comment info', payload);
                return [4 /*yield*/, api.sendComment(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.data.status == "approved")) return [3 /*break*/, 3];
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'comment-btn',
                    'event_category': 'community',
                    'event_action': 'comment-btn',
                    'event_label': payload.authorName //the article's author
                });
                return [4 /*yield*/, (0, effects_1.put)(actions.sendPostComment.success({ post: serverResponse.data.post, page: 1 }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.sendPostComment.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getPostRating(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.getPostRating(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status === 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.getPostRating.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.getPostRating.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendPostRating(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.sendPostRating(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!(serverResponse.status === 200)) return [3 /*break*/, 3];
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'rate-article',
                    'event_category': 'community',
                    'event_action': 'rate-article',
                    'event_label': payload.authorName,
                    'event_value': payload.rating //quantity of stars as the number
                });
                return [4 /*yield*/, (0, effects_1.put)(actions.sendPostRating.success(serverResponse.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.sendPostRating.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendReplyToPostComment(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log(payload);
                return [4 /*yield*/, api.sendReplyToPostComment(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.replyToPostComment.success({ post: serverResponse.data.post, page: 1 }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.replyToPostComment.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendUpdateToPostComment(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.sendUpdateToPostComment(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.updateToPostComment.success({ post: serverResponse.data.post, page: 1 }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.updateToPostComment.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendReportToPostComment(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.sendReportToPostComment(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.reportToPostComment.success({ post: serverResponse.data.post, page: 1 }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.reportToPostComment.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendUpVoteToPostComment(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.sendUpVoteToPostComment(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.reportToPostComment.success({ post: serverResponse.data.post }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.reportToPostComment.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function sendCuratePost(_a) {
    var serverResponse;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, api.sendCuratePostApi(payload)];
            case 1:
                serverResponse = _b.sent();
                if (!serverResponse.data) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)(actions.setCuratePost.success(serverResponse))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, (0, effects_1.put)(actions.setCuratePost.failure(serverResponse))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
