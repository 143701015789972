"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CreatorPageTranslation = [
    {
        en: {
            translations: {
                starsHeaderOne: "Create your SuperFan Club using",
                starsHeaderOneSpan: "Web3",
                starsHeaderTwo: "Reward them with Unique Benefits & Experiences",
                starsHeaderTwoSpan: "audience data",
                starsHeaderThree: "Engage your",
                starsHeaderThreeSpan: "SuperFans",
                starsGetStarted: "Get Started",
                starsSuperFansTitle: "Turning followers <br /> into <span>SuperFans</span>",
                starsSuperFansPublicPost: "Public post",
                starsSuperFansPublicPostMessage: "Likes, comments and engagement",
                starsSuperFansFanClub: "VIP membership clubs",
                starsSuperFansFanClubMessage: "Membership and subscriptions",
                starsSuperFansFans: "SuperFans",
                starsSuperFansFansMessage: "Limited edition content",
                starsOurBrandTitle: "We work with you to <br /> <span>expand your own brand</span>",
                starsOurBrandOne: "Create a customized <b>Fan Club</b> with <br /> <b>exclusive benefits</b> for fans",
                starsOurBrandTwo: "<b>Invite your followers</b> to join",
                starsOurBrandThree: "Start getting <b>recurring revenue</b>",
                starsCreativity: {
                    title: "Turn creativity <br /> into <span>benefits</span>",
                    itemOne: "<p><b>Generate more revenue</b><br /><span>True fans are willing to pay for exclusive content and more benefits</span></p>",
                    itemTwo: "<p><b>Gain more subscribers faster</b><br /><span>Provide unique and exclusive benefits to your fans</span></p>",
                    itemThree: "<p><b>Collaborate with brands and creators</b><br /><span>Partnerships for sponsorships, events and new projects</span></p>",
                },
                starsMultiPasses: {
                    title: "Create value with <span>NFT MultiPasses</span>",
                    subTitle: "Boost your popularity with benefits your fans will fall in love with",
                    list: [
                        {
                            title: "Membership & Passes",
                            sub_title: "Create multi passes that grant fans access to exclusive content",
                            url: "/creator/trip",
                            image: "https://loop-markets.directus.app/assets/48a3eaed-7bb4-4b0d-ba3c-f7cdb60aa594",
                            bg: "/img/mpbg1.png",
                        },
                        {
                            title: "Giveaways & Comps",
                            sub_title: "Gamify and engage fans with real-world prizes",
                            url: "/creator/camilasierra",
                            image: "https://loop-markets.directus.app/assets/2f2836ba-47e1-4853-974c-7e3d27e9fecd",
                            bg: "/img/mpbg2.png",
                        },
                        {
                            title: "Products & Merch",
                            sub_title: "Create NFTs that are redeemable for physical products and merch",
                            url: "/creator/camilopulgarin",
                            image: "https://loop-markets.directus.app/assets/2837d60c-61a2-4770-b76a-e401dcfc19b0",
                            bg: "/img/mpbg3.png",
                        },
                        {
                            title: "Private Communities",
                            sub_title: "Create VIP Memberships that allow fans to engage with you",
                            url: "/creator/trip",
                            image: "https://loop-markets.directus.app/assets/45d9eb03-efef-4bc3-bc61-0462479ba81c",
                            bg: "/img/mpbg4.png",
                        },
                        {
                            title: "Limited Offers",
                            sub_title: "Offer special discounts on products, services or events",
                            url: "/creator/camilasierra",
                            image: "https://loop-markets.directus.app/assets/e2a69131-5814-4830-92c6-2746729a807b",
                            bg: "/img/mpbg5.png",
                        },
                        {
                            title: "Crowdfunding",
                            sub_title: "Get support from fans to launch new projects and reward them as you grow",
                            url: "/creator/buenavidabeach",
                            image: "https://loop-markets.directus.app/assets/1881d939-1b5d-472c-8cab-5e3756503fa9",
                            bg: "/img/mpbg6.png",
                        },
                        {
                            title: "Digital Tickets",
                            sub_title: "Create multi tier Tickets for online and in real life events",
                            url: "/creator/andrewrod",
                            image: "https://loop-markets.directus.app/assets/02b2b836-65af-448b-b750-a5818452f0b4",
                            bg: "/img/mpbg7.png",
                        },
                        {
                            title: "Metaverse Experiences",
                            sub_title: "Unlock new dimensions with unique metaverse passes",
                            url: "/creator/starvibes",
                            image: "https://loop-markets.directus.app/assets/f67c2f37-81bc-49bb-8bbb-26c3d4d5ba3c",
                            bg: "/img/mpbg8.png",
                        },
                    ],
                },
                starsAudience: {
                    title: "Own your <br /> <span>Audience</span>",
                    itemOne: "<p> <b>Control over data</b> <br /> <span>Access email addresses and phone numbers</span> </p>",
                    itemTwo: "<p> <b>Learn what you audience wants</b> <br /> <span>Get behaviour insights on your SuperFans</span> </p>",
                    itemThree: "<p> <b>Give your fans what they love</b> <br /> <span>Get behaviour insights on your SuperFans</span> </p>",
                },
                starsCreatorPromo: {
                    title: "Are you a Creator <br /> or Influencer?",
                    subText: "Hundreds of creators have started taking ownership <br /> of their creativity and monetizing their content",
                    buttonText: "Create my fan club",
                },
                creatorEventCountdown: "Event starts in",
                creatorNFTCountdown: "Going on sale in"
            },
        },
        es: {
            translations: {
                starsHeaderOne: "Crea tu Club de Superfans usando",
                starsHeaderOneSpan: "Web3",
                starsHeaderTwo: "Recompénsalos con Beneficios y Experiencias Únicas",
                starsHeaderTwoSpan: "datos de audiencia",
                starsHeaderThree: "Involucra a tus",
                starsHeaderThreeSpan: "SuperFans",
                starsGetStarted: "Comenzar",
                starsSuperFansTitle: "Convirtiendo seguidores <br /> en <span>SuperFans</span>",
                starsSuperFansPublicPost: "Post Público",
                starsSuperFansPublicPostMessage: "Likes, Comentarios y Engagement",
                starsSuperFansFanClub: "Clubes de Membresía VIP",
                starsSuperFansFanClubMessage: "Membresías y Suscripciones",
                starsSuperFansFans: "SuperFans",
                starsSuperFansFansMessage: "Contenido de edición limitada",
                starsOurBrandTitle: "Trabajamos contigo para <br /> <span>expandir tu propia marca</span>",
                starsOurBrandOne: "Crea un <b>Club de Fans</b> personalizado con <b>beneficios exclusivos</b> para los fans",
                starsOurBrandTwo: "<b>Invita a tus seguidores</b> a unirse",
                starsOurBrandThree: "Comienza a recibir <b>ingresos recurrentes</b>",
                starsCreativity: {
                    title: "Convierte la creatividad <br /> en <span>beneficios</span>",
                    itemOne: "<p><b>Genera más ingresos</b><br /><span>Los verdaderos fans están dispuestos a pagar por contenido exclusivo y más beneficios</span></p>",
                    itemTwo: "<p><b>Obtén más suscriptores más rápido</b><br /><span>Brinda beneficios únicos y exclusivos a tus fans</span></p>",
                    itemThree: "<p><b>Colabora con marcas y creadores</b><br /><span>Asociaciones para patrocinios, eventos y nuevos proyectos</span></p>",
                },
                starsMultiPasses: {
                    title: "Crea valor con <span>MultiPasses</span>",
                    subTitle: "Impulsa tu popularidad con beneficios que tus fans amarán",
                    list: [
                        {
                            title: "Membresías y Pases",
                            sub_title: "Crea pases múltiples que otorgan a los fans acceso a contenido exclusivo",
                            url: "/creator/trip",
                            image: "https://loop-markets.directus.app/assets/48a3eaed-7bb4-4b0d-ba3c-f7cdb60aa594",
                            bg: "/img/mpbg1.png",
                        },
                        {
                            title: "Regalos y Competiciones",
                            sub_title: "Gamifica e involucra a los fans con premios del mundo real",
                            url: "/creator/camilasierra",
                            image: "https://loop-markets.directus.app/assets/2f2836ba-47e1-4853-974c-7e3d27e9fecd",
                            bg: "/img/mpbg2.png",
                        },
                        {
                            title: "Productos y Merch",
                            sub_title: "Crea versiones digitales de productos físicos y merch",
                            url: "/creator/camilopulgarin",
                            image: "https://loop-markets.directus.app/assets/2837d60c-61a2-4770-b76a-e401dcfc19b0",
                            bg: "/img/mpbg3.png",
                        },
                        {
                            title: "Comunidades Privadas",
                            sub_title: "Crea membresías VIP que permitan a los fans interactuar contigo",
                            url: "/creator/trip",
                            image: "https://loop-markets.directus.app/assets/45d9eb03-efef-4bc3-bc61-0462479ba81c",
                            bg: "/img/mpbg4.png",
                        },
                        {
                            title: "Ofertas Limitadas",
                            sub_title: "Ofrece descuentos especiales en productos, servicios o eventos",
                            url: "/creator/camilasierra",
                            image: "https://loop-markets.directus.app/assets/557daa3e-7ed8-446c-970e-3b87e7b3e09e",
                            bg: "/img/mpbg5.png",
                        },
                        {
                            title: "Crowdfunding",
                            sub_title: "Obtén el apoyo de los fans para lanzar nuevos proyectos y recompénsalos a medida que creces",
                            url: "/creator/buenavidabeach",
                            image: "https://loop-markets.directus.app/assets/1881d939-1b5d-472c-8cab-5e3756503fa9",
                            bg: "/img/mpbg6.png",
                        },
                        {
                            title: "Entradas Digitales",
                            sub_title: "Crea entradas de varios niveles para eventos en línea y en la vida real",
                            url: "/creator/andrewrod",
                            image: "https://loop-markets.directus.app/assets/e4d04c69-72eb-485d-b8c0-cf37e9559c54",
                            bg: "/img/mpbg7.png",
                        },
                        {
                            title: "Experiencias Metaverso",
                            sub_title: "Desbloquea nuevas dimensiones con pases únicos de metaverso",
                            url: "/creator/starvibes",
                            image: "https://loop-markets.directus.app/assets/f67c2f37-81bc-49bb-8bbb-26c3d4d5ba3c",
                            bg: "/img/mpbg8.png",
                        },
                    ],
                },
                starsAudience: {
                    title: "Posee tu <br /> <span>Audiencia</span>",
                    itemOne: "<p> <b>Control sobre datos</b> <br /> <span>Accede a direcciones de correo electrónico y números de teléfono</span> </p>",
                    itemTwo: "<p> <b>Aprende lo que quiere tu audiencia</b> <br /> <span>Obtén información sobre el comportamiento de tus SuperFans</span> </p>",
                    itemThree: "<p> <b>Ofrece a tus fans lo que aman</b> <br /> <span>Obtén información sobre el comportamiento de tus SuperFans</span> </p>",
                },
                starsCreatorPromo: {
                    title: "¿Eres un Creador <br /> o Influencer?",
                    subText: "Cientos de creadores han comenzado a tomar posesión <br /> de su creatividad y monetizar su contenido",
                    buttonText: "Crea mi club de fans",
                },
                creatorEventCountdown: "El evento comienza en",
                creatorNFTCountdown: "La venta empieza en"
            },
        },
    },
];
exports.default = CreatorPageTranslation;
