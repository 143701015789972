"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StarsPagesTranslation = [
    {
        en: {
            translations: {
                starsFitness: {
                    title: "For Health Professionals <br /> Fitness and Wellness",
                    contentTitle: "Use digital collectibles <br /> to <span> interact, monetize </span> and create",
                    list: [
                        {
                            name: "<p> Give fans <b>membership passes</b> to your community </p>",
                            bg: "/assets/images/stars/health_61.png",
                        },
                        {
                            name: "<p> <b>Share exclusive content</b> like classes and tips </p>",
                            bg: "/assets/images/stars/health_62.png",
                        },
                        {
                            name: "<p> Generate excitement through <b>giveaways</b> </p>",
                            bg: "/assets/images/stars/health_63.png",
                        },
                    ],
                },
                starsInfluencers: {
                    title: "<h3>Influencers: Empower your <br /> followers with Digital <br /> Collectibles</h3>",
                    contentTitle: "Entertain <span> fans and followers </span> <br /> while <span> monetizing </span> your content",
                    list: [
                        {
                            name: "<p> Share <b>Exclusive Content</b> </p>",
                            bg: "/assets/images/stars/influencer/influ01.png",
                        },
                        {
                            name: "<p> Create your own <b>Private Community</b> </p>",
                            bg: "/assets/images/stars/influencer/influ02.png",
                        },
                        {
                            name: "<p> <b>Monetize</b> your content and explore <b>sponsorship opportunities</b> </p>",
                            bg: "/assets/images/stars/influencer/influ03.png",
                        },
                    ],
                },
                starsSports: {
                    title: "<h3>For Athletes: Animate <br /> your Fans with <br /> Digital Collectibles</h3>",
                    contentTitle: "Stimulate <span> fan interaction </span> <br /> while <span> generating revenue </span>",
                    list: [
                        {
                            name: "<p> Launch <b>Collectible Card</b> series </p>",
                            bg: "/assets/images/stars/sports/sports01.png",
                        },
                        {
                            name: "<p> Launch Tickets through <b>NFTs</b> </p>",
                            bg: "/assets/images/stars/sports/sports02.png",
                        },
                        {
                            name: "<p> Create Merchandise via <b>NFTs</b> </p>",
                            bg: "/assets/images/stars/sports/sports03.png",
                        },
                        {
                            name: "<p> Explore <b>partnership</b> opportunities </p>",
                            bg: "/assets/images/stars/sports/sports04.png",
                        },
                        {
                            name: "<p> Support a <b>fundraising</b> campaign </p>",
                            bg: "/assets/images/stars/sports/sports05.png",
                        },
                    ],
                },
                starsMusic: {
                    title: "<h3>Share your Music directly <br /> with Fans through <br /> Digital Collectibles</h3>",
                    contentTitle: "The best way to <br /> <span>Monetize and Interact with your Fans</span> <br /> doing what you love",
                    list: [
                        {
                            name: "<p> Launch Limited Editions <b>of music for your community</b> </p>",
                            bg: "/assets/images/collectibles/music01.png",
                        },
                        {
                            name: "<p> Create Connection with your <b>Fans within</b> your own community </p>",
                            bg: "/assets/images/collectibles/music02.png",
                        },
                        {
                            name: "<p> Share music with your fans and <b>generate a direct source of income</p>",
                            bg: "/assets/images/collectibles/music03.png",
                        }
                    ]
                },
            },
        },
        es: {
            translations: {
                starsFitness: {
                    title: "<h3> Para Profesionales de la Salud <br /> el Fitness y el Bienestar </h3>",
                    contentTitle: "Utiliza coleccionables digitales <br /> para <span> interactuar, monetizar</span> y crear",
                    list: [
                        {
                            name: "<p> Brinda a los fans <b>pases de membresía a tu comunidad</b> </p>",
                            bg: "/assets/images/stars/health_61.png",
                        },
                        {
                            name: "<p> <b>Comparte contenido exclusivo</b> como clases y consejos </p>",
                            bg: "/assets/images/stars/health_62.png",
                        },
                        {
                            name: "<p> Genera expectativa por medio de <b>giveaways</b> </p>",
                            bg: "/assets/images/stars/health_63.png",
                        },
                    ],
                },
                starsInfluencers: {
                    title: "<h3>Influenciadores: Potencia a tus <br /> seguidores con Coleccionables <br /> Digitales</h3>",
                    contentTitle: "Entretene <span>fans y seguidores</span> <br /> mientras <span>monetizas</span> tu contenido",
                    list: [
                        {
                            name: "<p> Comparte <b>Contenido Exclusivo</b> </p>",
                            bg: "/assets/images/stars/influencer/influ01.png",
                        },
                        {
                            name: "<p> Crea tu propia <b>Comunidad Privada</b> </p>",
                            bg: "/assets/images/stars/influencer/influ02.png",
                        },
                        {
                            name: "<p> <b>Monetiza</b> tu contenido y explora <b>oportunidades de patrocinio</b> </p>",
                            bg: "/assets/images/stars/influencer/influ03.png",
                        },
                    ],
                },
                starsSports: {
                    title: "<h3>Para Deportistas y Atletas: Anima <br /> tus Fans con <br /> Coleccionables Digitales</h3>",
                    contentTitle: "Estimula la <span>interacción</span> de tus <br /> Fans mientras <span>genera ingresos</span>",
                    list: [
                        {
                            name: "<p>Lanza series de Tarjetas <b>Coleccionables</b></p>",
                            bg: "/assets/images/stars/sports/sports01.png",
                        },
                        {
                            name: "<p>Lanza Tickets a través de <b>NFTs</b></p>",
                            bg: "/assets/images/stars/sports/sports02.png",
                        },
                        {
                            name: "<p>Crea Merchandise via <b>NFTs</b></p>",
                            bg: "/assets/images/stars/sports/sports03.png",
                        },
                        {
                            name: "<p>Explora oportunidades de  <b>asociación</b></p>",
                            bg: "/assets/images/stars/sports/sports04.png",
                        },
                        {
                            name: "<p>Apoye una recaudación de <b>fondos</b></p>",
                            bg: "/assets/images/stars/sports/sports05.png",
                        },
                    ],
                },
                starsMusic: {
                    title: "<h3>Comparte tu Música directamente <br /> con Fans a través de <br /> Coleccionables Digitales</h3>",
                    contentTitle: "La mejor forma para <br /> <span>Monetizar e Interactuar con tus Fans</span> <br /> haciendo lo que te apasiona",
                    list: [
                        {
                            name: "<p>Lanza Ediciones Limitadas <b>de música para tu comunidad</b></p>",
                            bg: "/assets/images/collectibles/music01.png",
                        },
                        {
                            name: "<p>Crea Conexión con tus <b>Fans dentro</b> de tu propia comunidad</p>",
                            bg: "/assets/images/collectibles/music02.png",
                        },
                        {
                            name: "<p>Comparte música con tus fans y <b>genera una fuente directa de ingresos</p>",
                            bg: "/assets/images/collectibles/music03.png",
                        },
                    ],
                },
            },
        },
    },
];
exports.default = StarsPagesTranslation;
