"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserHelperData = exports.getMentionSuggestions = exports.rollPostBack = exports.expandPost = exports.deleteReaction = exports.createSocialComment = exports.like = exports.cleanSocialFeed = exports.createSocialPost = exports.getSocialFeed = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
exports.getSocialFeed = (0, redux_saga_routines_1.createRoutine)('socialFeed/GET_SOCIAL_FEED');
exports.createSocialPost = (0, redux_saga_routines_1.createRoutine)('socialFeed/CREATE_SOCIAL_POST');
exports.cleanSocialFeed = (0, redux_saga_routines_1.createRoutine)('socialFeed/CLEAN');
exports.like = (0, redux_saga_routines_1.createRoutine)('socialFeed/LIKE');
exports.createSocialComment = (0, redux_saga_routines_1.createRoutine)('socialFeed/CREATE_SOCIAL_COMMENT');
exports.deleteReaction = (0, redux_saga_routines_1.createRoutine)('socialFeed/DELETE_REACTION');
exports.expandPost = (0, redux_saga_routines_1.createRoutine)('socialFeed/EXPAND_POST');
exports.rollPostBack = (0, redux_saga_routines_1.createRoutine)('socialFeed/ROLL_POST_BACK');
exports.getMentionSuggestions = (0, redux_saga_routines_1.createRoutine)('socialFeed/GET_MENTION_SUGGESTIONS');
exports.getUserHelperData = (0, redux_saga_routines_1.createRoutine)('socialFeed/GET_USER_HELPER');
