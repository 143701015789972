"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var feedActions = __importStar(require("./feedActions"));
var defaultState = {
    loading: false,
    posts: [],
    isEnd: false,
    mappedPosts: [],
    noAuthor: false
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case feedActions.getBountyPostsRoutine.TRIGGER: {
            // const newState = state.set('loading', true);
            return __assign(__assign({}, state), { loading: true });
        }
        case feedActions.getBountyPostsRoutine.SUCCESS: {
            // const postsState = state.update('posts', posts => posts.push(payload));
            // const newState = postsState.set('loading', false);
            if (payload.length === 0) {
                return __assign(__assign({}, state), { posts: __spreadArray(__spreadArray([], state.posts, true), payload, true), isEnd: true, loading: false, noAuthor: false });
            }
            return __assign(__assign({}, state), { posts: __spreadArray(__spreadArray([], state.posts, true), payload, true), loading: false, noAuthor: false });
        }
        case feedActions.getBountyPostsRoutine.FAILURE: {
            // const newState = state.set('loading', false);
            var newState = __assign(__assign({}, state), { loading: false });
            console.log(payload);
            if (payload.data.code === 'rest_post_invalid_page_number') {
                return __assign(__assign({}, newState), { isEnd: true, noAuthor: false });
            }
            if (payload.data.code === 'rest_invalid_param') {
                return __assign(__assign({}, newState), { isEnd: true, noAuthor: true });
            }
            return newState;
        }
        case feedActions.clearBountyPostsRoutine.TRIGGER: {
            // return state.set('posts', defaultState.get('posts')).set('isEnd', false);
            return __assign(__assign({}, state), { posts: [], isEnd: false });
        }
        default:
            return state;
    }
}
exports.default = reducer;
