"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var imageUploadActions = __importStar(require("./ImageAddActions"));
var defaultState = {
    imageIsUploaded: false,
    uploadingImage: false,
    imageUrl: null
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case imageUploadActions.uploadImageRoutine.TRIGGER: {
            // const newState = state
            //   .set("imageIsUploaded", false)
            //   .set("uploadingImage", true);
            return __assign(__assign({}, state), { imageIsUploaded: false, uploadingImage: true });
        }
        case imageUploadActions.uploadImageRoutine.SUCCESS: {
            // const newState = state
            //   .set('imageIsUploaded', true)
            //   .set('uploadingImage', false)
            //   .set('imageUrl', payload.source_url);
            return __assign(__assign({}, state), { imageIsUploaded: true, uploadingImage: false, imageUrl: payload.source_url });
        }
        case imageUploadActions.uploadImageRoutine.FAILURE: {
            // const newState = state
            //   .set('imageIsUploaded', false)
            //   .set('uploadingImage', false);
            return __assign(__assign({}, state), { imageIsUploaded: false, uploadingImage: false });
        }
        default:
            return state;
    }
}
exports.default = reducer;
