"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendNewPassword = exports.forgotPasswordRequest = exports.returnToLoginMode = exports.forgotPasswordClick = exports.userSignOut = exports.setScroll = exports.forceUserLogin = exports.getUserData = exports.postLogOut = exports.postLogin = void 0;
var redux_saga_routines_1 = require("redux-saga-routines");
exports.postLogin = (0, redux_saga_routines_1.createRoutine)('USER/LOGIN');
exports.postLogOut = (0, redux_saga_routines_1.createRoutine)('USER/LOGIN_OUT');
exports.getUserData = (0, redux_saga_routines_1.createRoutine)('USER/GET_USER_DATA');
exports.forceUserLogin = (0, redux_saga_routines_1.createRoutine)('USER/FORCE_LOGIN');
exports.setScroll = (0, redux_saga_routines_1.createRoutine)('SETSCROLL');
exports.userSignOut = (0, redux_saga_routines_1.createRoutine)('USER/SIGN_OUT');
exports.forgotPasswordClick = (0, redux_saga_routines_1.createRoutine)('USER/FORGOT_PASSWORD_CLICK');
exports.returnToLoginMode = (0, redux_saga_routines_1.createRoutine)('USER/RETURN_TO_LOGIN_MODE');
exports.forgotPasswordRequest = (0, redux_saga_routines_1.createRoutine)('USER/FORGOT_PASSWORD_REQUEST');
exports.sendNewPassword = (0, redux_saga_routines_1.createRoutine)('SEND_NEW_PASSWORD');
