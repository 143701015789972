"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var navMenuTranslation = [
    {
        en: {
            translations: {
                navHome: "Home",
                navForFans: "For Fans",
                vipClubs: "VIP Clubs",
                navForCreators: "For Creators & Brands",
                navForArtists: "For Artists",
                navWallet: "My wallet",
                navTypesOfCollectibles: "Types of Collectibles",
                navFanClubs: "Fan Clubs",
                navSports: "Sports & Athletes",
                navInfluencers: "Influencers",
                navFitness: "Health, Fitness & Wellness",
                navMusic: "Musicians",
                navForCreatorsDropdown: "LoopFans For Creators and $t(navMusic)",
                nevTypes: "All Types",
                navTypesEvents: "Concerts & Event Tickets",
                navTypesMembership: "Private Memberships",
                navTypesDigital: "Digital Media",
                navTypesMusic: "Music NFTs",
                membersArea: "Members Area",
            },
        },
        es: {
            translations: {
                navHome: "Home",
                navForFans: "Para Fans",
                vipClubs: "VIP Clubs",
                navForCreators: "Para Creadores y Marcas",
                navForArtists: "Para Artistas",
                navWallet: "Mi Billetera",
                navTypesOfCollectibles: "Tipos de Coleccionables",
                navFanClubs: "Fan Clubs",
                navSports: "Deportistas y Atletas",
                navInfluencers: "Influenciadores",
                navFitness: "Salud, Fitness y Bienstar",
                navMusic: "Músicos",
                navForCreatorsDropdown: "LoopFans Para Creadores",
                nevTypes: "Todos los Tipos",
                navTypesEvents: "Concertos y Tickets para Eventos",
                navTypesMembership: "Membresias Privadas",
                navTypesDigital: "Medios Digitales",
                navTypesMusic: "Music NFTs",
                membersArea: "Area de Miembros",
            },
        },
    },
];
exports.default = navMenuTranslation;
