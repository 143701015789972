"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions = __importStar(require("./FollowingActions"));
var defaultState = {
    loadingSubscribe: false,
    subscriptionSuccessfull: false,
    loadingList: true,
    followers: [],
    following: [],
    loadingIndexes: {}
};
function reducer(state, action) {
    if (state === void 0) { state = defaultState; }
    var payload = action.payload;
    switch (action.type) {
        case actions.getFollowing.TRIGGER:
        case actions.getFollowers.TRIGGER: {
            return __assign(__assign({}, state), { loadingList: true });
        }
        case actions.getFollowing.SUCCESS: {
            localStorage.setItem('trybe_following', JSON.stringify(payload));
            return __assign(__assign({}, state), { loadingList: false, following: payload });
        }
        case actions.getFollowers.SUCCESS: {
            return __assign(__assign({}, state), { loadingList: false, followers: payload });
        }
        case actions.getFollowing.FAILURE:
        case actions.getFollowers.FAILURE: {
            return __assign(__assign({}, state), { loadingList: false });
        }
        case actions.follow.TRIGGER: {
            var index = payload.index;
            var loadingIndexes = state.loadingIndexes;
            if (index !== undefined)
                loadingIndexes[index] = true;
            return __assign(__assign({}, state), { loadingSubscribe: true, subscriptionSuccessfull: false, loadingIndexes: loadingIndexes });
        }
        case actions.follow.SUCCESS: {
            var loadingIndexes = state.loadingIndexes;
            var index = payload.index;
            if (index !== undefined)
                loadingIndexes[index] = null;
            return __assign(__assign({}, state), { loadingSubscribe: false, subscriptionSuccessfull: true });
        }
        case actions.follow.FAILURE: {
            var index = payload.index;
            var loadingIndexes = state.loadingIndexes;
            if (index !== undefined)
                loadingIndexes[index] = null;
            return __assign(__assign({}, state), { loadingSubscribe: false, subscriptionSuccessfull: false, loadingIndexes: loadingIndexes });
        }
        case actions.unfollow.TRIGGER: {
            var index = payload.index;
            var loadingIndexes = state.loadingIndexes;
            if (index !== undefined)
                loadingIndexes[index] = true;
            return __assign(__assign({}, state), { loadingSubscribe: true, subscriptionSuccessfull: false, loadingIndexes: loadingIndexes });
        }
        case actions.unfollow.SUCCESS: {
            var loadingIndexes = state.loadingIndexes;
            var index = payload.index;
            if (index !== undefined)
                loadingIndexes[index] = null;
            return __assign(__assign({}, state), { loadingSubscribe: false, subscriptionSuccessfull: true });
        }
        case actions.unfollow.FAILURE: {
            var index = payload.index;
            var loadingIndexes = state.loadingIndexes;
            if (index !== undefined)
                loadingIndexes[index] = null;
            return __assign(__assign({}, state), { loadingSubscribe: false, subscriptionSuccessfull: false, loadingIndexes: loadingIndexes });
        }
        default:
            return state;
    }
}
exports.default = reducer;
